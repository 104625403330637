import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Row } from 'react-bootstrap';
import './ContactForm.scss';
import { sendEmailPOST } from '../../../apis/APIs';

const ContactForm = ({ onClose }) => {
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onNameChange = (event) => setName(event.target.value);
    const onEmailChange = (event) => setEmail(event.target.value);
    const onContentChange = (event) => setContent(event.target.value);
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!email || !content) {
            setError('Either email or content is empty');
            console.error('Either email or content is empty');
        }
        setIsLoading(true);
        await sendEmailPOST(name, email, content)
            .catch((e) => {
                setError(e);
                console.error(e);
            })
            .finally(() => {
                setIsLoading(false);
                onClose();
            });
    };

    return (
        <div className="form">
            <div className="form-banner">Leave us a message</div>
            <form onSubmit={onSubmit}>
                <FormControl className="contact-form">
                    <Row className="mb-4">
                        <TextField
                            autoFocus
                            fullWidth
                            id="your-name"
                            label="Your name"
                            variant="outlined"
                            onChange={onNameChange}
                            value={name}
                        />
                    </Row>
                    <Row className="mb-4">
                        <TextField
                            required
                            fullWidth
                            id="email-adrress"
                            label="Email Address"
                            type="email"
                            variant="outlined"
                            onChange={onEmailChange}
                            value={email}
                        />
                    </Row>
                    <Row className="mb-4">
                        <TextField
                            required
                            fullWidth
                            multiline
                            rows={4}
                            id="how-can-we-help"
                            label="How can we help you"
                            variant="outlined"
                            onChange={onContentChange}
                            value={content}
                        />
                    </Row>
                    <Row className="mb-4">
                        <Button
                            style={{ textTransform: 'none', borderRadius: '10px' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            Send
                        </Button>
                    </Row>
                </FormControl>
            </form>
        </div>
    );
};

export default ContactForm;

import { Box, Dialog } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
export const TabContentBox = styled(Box)`
    background: ${(props) => {
        props.className === 'bg-white' ? 'white' : '#f3f4f6';
    }};
    height: calc(100vh - 165px);
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    .MuiButton-containedPrimary,
    .MuiButton-outlinedPrimary {
        border-radius: 10px;
        padding: 8px 16px;
        text-transform: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    .MuiButton-outlinedPrimary {
        border: 1px solid #165ebf;
    }
`;

export const TabContentStatusBox = styled(Box)`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0px;
`;

export const ActionButtonRow = styled.div`
    position: absolute;
    bottom: 25px;
    right: 25px;
`;

export const ReferralTrackerStatusRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin: 30px 30px 0 30px;

    .MuiToggleButton-root {
        padding: 4px 20px;
        background: #c4c4c4;
        border-radius: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        text-transform: none;
        margin-right: 60px;
    }

    .MuiButtonBase-root.Mui-disabled {
        color: white;
    }
    .MuiToggleButton-root.Mui-selected {
        background: #165ebf;
        color: white;
    }

    .MuiToggleButton-root.Mui-selected:hover {
        background-color: #0d499a;
        color: white;
    }
`;

export const ReferralDashboardListCellBox = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding: 0 30px 30px 30px;
    position: relative;
    margin-top: 30px;
    height: 100%;
`;

export const ReferralApplicationCellRow = styled(Row)`
    border-bottom: 1px solid rgba(133, 137, 149, 0.5);
    padding: 15px 20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background: ${(props) => {
        if (props.className === 'highlighted') {
            return '#f3f4f6';
        }
    }};
`;

export const ReferralApplicationCellCol = styled(Col)`
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
`;

export const ReferralApplicationCellCompanyImg = styled.div`
    margin-right: 20px;
    img {
        height: auto;
        width: 40px;
    }
`;

export const ReferralApplicationCellCompanyInfo = styled.div`
    display: flex;
    flex-flow: column wrap;
`;

export const CompanyLogoContainer = styled.div`
    align-items: start;
    padding-left: 15px;
    .company-logo {
        width: 40px;
    }
`;

export const ReferralApplicationPrimaryTitle = styled.div`
    margin-bottom: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #0b132c;
`;

export const ReferralApplicationSecondaryTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #858995;
`;

export const ReferralApplicationStatusDot = styled.span`
    background-color: ${(props) => {
        if (props.className === 'pending') {
            return '#dbb759';
        } else if (props.className === 'referred') {
            return '#4D78D9';
        } else if (props.className === 'declined') {
            return '#858995';
        } else if (props.className === 'unfinished') {
            return '#B3B3B3';
        }
    }};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
`;

export const ReferralStatus = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-transform: capitalize;
    margin-right: 30px;
`;

export const ActionAlertDialog = styled(Dialog)`
    .MuiTypography-subtitle1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        color: #0b132c;
    }

    .MuiTypography-body2 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #858995;
    }
    .MuiPaper-root {
        padding: 30px;
        border-radius: 10px;
    }

    .MuiButton-containedPrimary,
    .MuiButton-outlinedPrimary {
        border-radius: 10px;
        padding: 8px 16px;
        text-transform: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    .MuiButton-outlinedPrimary {
        border: 1px solid #165ebf;
    }
`;

export const ReferralApplicationTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #165ebf;
    margin-bottom: 40px;
`;

export const PostInfoRow = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-flow: row wrap;
`;

import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from 'aws-amplify/node_modules/@aws-amplify/auth';

/*
    API Reference: https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#confirm-sign-up
*/

// Create a new user in the Amazon Cognito UserPool by passing the new user’s email address, password, and other attributes to Auth.signUp
//
// The Auth.signUp promise returns a data object of type ISignUpResult with a CognitoUser.
// {
//     user: CognitoUser;
//     userConfirmed: boolean;
//     userSub: string;
// }
async function signUp(password: string, email: string, userid: string) {
    return await Auth.signUp({
        username: email.toString(),
        password: password.toString(),
        attributes: {
            email: email, // optional
            // phone_number,   // optional - E.164 number convention
            // other custom attributes
            'custom:user_id': userid.toString(),
            'custom:has_set_preferences': '0',
            'custom:is_internal': checkInternalUser(email),
        },
    });
}

function checkInternalUser(email: string) {
    return email.split('@').pop() === 'aitou.io' ? '0' : '1';
}

// If you enabled multi-factor auth, confirm the sign-up after retrieving a confirmation code from the user.
async function confirmSignUp(username: string, code: string) {
    return await Auth.confirmSignUp(username.toString(), code.toString());
}

async function signIn(username: string, password: string) {
    return await Auth.signIn(username.toString(), password.toString());
}

async function resendConfirmationCode(username: string) {
    return await Auth.resendSignUp(username);
}

async function signOut(signOutAllDevices = false) {
    return await Auth.signOut({ global: signOutAllDevices });
}

async function updateUserAttributes(attributes: Record<string, any>) {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, attributes);
}

async function currentUserInfo() {
    return await Auth.currentUserInfo();
}

async function forgotPassword(username: string) {
    return await Auth.forgotPassword(username);
}

async function forgotPasswordSubmit(username: string, code: string, password: string) {
    return await Auth.forgotPasswordSubmit(username.toString(), code.toString(), password.toString());
}

async function signInWithGoogle() {
    return await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
}

export {
    signUp,
    confirmSignUp,
    signIn,
    resendConfirmationCode,
    signOut,
    updateUserAttributes,
    currentUserInfo,
    forgotPassword,
    forgotPasswordSubmit,
    signInWithGoogle,
};

import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import ContactForm from './form/ContactForm';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

const MessageMeContainer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    right: 30px;
    bottom: 30px;
    align-items: center;
`;

const MessageMeIcon = styled.div`
    position: relative;
    width: 54px;
    height: 54px;

    background: white;
    border-radius: 50%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
`;

const WechatIcon = styled.div`
    margin-right: 20px;
    position: relative;
    background: white;
    border-radius: 50%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
`;

const PaperComponent = (props) => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
};
const MessageMe = () => {
    const [open, setOpen] = useState(false);
    const [openWechatQRCode, setOpenWechatQRCode] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleOpenWechatQRCode = () => {
        setOpenWechatQRCode(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseWechatQRCode = () => {
        setOpenWechatQRCode(false);
    };

    return (
        <MessageMeContainer>
            <WechatIcon>
                <Tooltip title="Got wechat? Join our community!">
                    <IconButton onClick={handleOpenWechatQRCode}>
                        <img src="https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/wechatQRCode.svg" />
                    </IconButton>
                </Tooltip>
            </WechatIcon>
            {/* TODO: Redesign */}
            {/* <MessageMeIcon>
                <Tooltip title="Have questions? Contact us here!">
                    <IconButton onClick={handleClickOpen}>
                        <MailOutlineIcon style={{ fontSize: '30px' }} />
                    </IconButton>
                </Tooltip>
            </MessageMeIcon> */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="form-dialog-title"
            >
                <ContactForm onClose={() => handleClose()} />
            </Dialog>
            <Dialog
                open={openWechatQRCode}
                onClose={handleCloseWechatQRCode}
                PaperComponent={PaperComponent}
                aria-labelledby="form-dialog-title"
            >
                <img
                    src="https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/wechatQR.png"
                    onClick={() => handleCloseWechatQRCode()}
                />
            </Dialog>
        </MessageMeContainer>
    );
};

export default MessageMe;

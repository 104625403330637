import { API } from 'aws-amplify';
import { getAuthToken, getPayload, toBase64 } from './helpers';
import { toKababCaseWithPlusSign } from '../../Constants';

export const subscribe = async (email) => {
    const payload = {
        body: {
            data: {
                email: email,
            },
        },
    };
    return API.put('api', 'subscriptions', payload);
};

export const userProfilePatch = async ({
    id,
    profileImageFilename,
    profileImageContent,
    firstName,
    lastName,
    location,
    preferredRoleChips,
}) => {
    const data = {};

    if (profileImageFilename && profileImageContent) {
        const convertedContent = await toBase64(profileImageContent);
        if (convertedContent) {
            data['profile_image_filename'] = profileImageFilename;
            data['profile_image_data'] = convertedContent.split(',')[1];
        }
    }

    if (firstName || lastName) {
        data['first_name'] = firstName;
        data['last_name'] = lastName;
    }

    if (location) {
        data['location'] = toKababCaseWithPlusSign(location);
    }

    if (preferredRoleChips && preferredRoleChips.length > 0) {
        data['preferred_roles'] = preferredRoleChips.map((role) => toKababCaseWithPlusSign(role)).join(';');
    }

    const payload = await getAuthToken(getPayload(data));
    return API.patch('api', `users/${id}`, payload);
};

export const userAttachmentInfoGet = async ({ userId, attachmentId }) => {
    const payload = await getAuthToken({});
    return API.get('api', `users/${userId}/attachments/${attachmentId}`, payload);
};

export const userResumePost = async ({ userId, resumeFilename, resumeContent }) => {
    const data = {};
    if (resumeFilename && resumeContent) {
        const convertedContent = await toBase64(resumeContent);
        if (convertedContent) {
            data['file_name'] = resumeFilename;
            data['resume_data'] = convertedContent.split(',')[1];
        }
    }
    const payload = await getAuthToken(getPayload(data));
    return API.post('api', `users/${userId}/resumes`, payload);
};

export const userResumeDelete = async ({ userId }) => {
    const payload = await getAuthToken(getPayload({}));
    return API.del('api', `users/${userId}/resumes`, payload);
};

import React from 'react';
import DomainIcon from '@material-ui/icons/Domain';
import { COMPANY_LOGO_PATH } from '../../Constants';
import styled from 'styled-components';

export interface CompanyLogoProps {
    companyLogoFilename: string;
    companyTitle?: string;
    size?: number;
    className?: string;
}

const CompanyImg = styled.img`
    width: ${(props) => `${props.size}px`};
    height: auto;
`;

const CompanyLogo = ({ companyLogoFilename, companyTitle, size, className }: CompanyLogoProps) => {
    const finalSize = size ? size : 40;
    const replacementIconSize = size ? size : 40;

    const compressCompanyName = (str: string) => {
        if (!str) return str;
        const noSpace: string = str.replace(/\s/g, '');
        return noSpace.toLocaleLowerCase();
    };
    const imgSrc = companyLogoFilename
        ? COMPANY_LOGO_PATH + companyLogoFilename
        : `//logo.clearbit.com/${compressCompanyName(companyTitle)}.com`;

    return (
        <React.Fragment>
            {companyLogoFilename ? (
                <CompanyImg className={className} size={finalSize} src={imgSrc} />
            ) : (
                <DomainIcon style={{ fontSize: replacementIconSize, color: 'gray' }} />
            )}
        </React.Fragment>
    );
};

export default CompanyLogo;

import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const ReferralPostApplicationFormBox = styled.div`
    padding: 40px;
    min-height: calc(100vh - 160px);
    width: 100%;
    background-color: white;
    position: relative;
    border-radius: 10px;
`;

export const ReferralPostDetailBox = styled.div`
    padding: 30px;
    height: 100vh;
    background-color: white;
`;
export const ErrorMsg = styled.div`
    display: flex;
    justify-content: end;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    /* Secondary/Red */

    color: #d83434;
`;

// Sits besides a input field
export const ActionButtonContainer = styled.div`
    .MuiButton-root {
        margin-left: 10px;
        text-transform: none;
        font-family: 'Roboto';
        line-height: 20px;
        font-weight: 400;
        font-size: 14px;
        border-radius: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

// Sits at right bottom of the page
export const ActionButtonGroup = styled(ActionButtonContainer)`
    display: flex;
    flex-flow: column wrap;
    position: absolute;
    bottom: 25px;
    right: 25px;
    justify-content: end;
    .MuiButton-root {
        width: fit-content;
        margin-left: auto;
    }
`;

export const SubmitErrorMsg = styled(ErrorMsg)`
    margin-bottom: 10px;
`;

// Sits at the right top of the page
export const CancelFormButtonGroup = styled(ActionButtonContainer)`
    position: absolute;
    left: -60px;
    top: 0px;
    cursor: pointer;
`;

export const ApplicationFieldRow = styled.div`
    padding-bottom: 20px;
`;

export const RequestReferralConfirmDialog = styled(Dialog)`
    border-radius: 10px;
    .MuiPaper-root {
        padding: 40px;
        border-radius: 10px;
    }
`;

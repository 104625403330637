import { Auth } from 'aws-amplify';
import { toTitleCaseFromKababCase } from '../../Constants';
import Job from '../../interfaces/Job';
import Company from '../../interfaces/Company';
import H1BDetail from '../../interfaces/H1BDetail';
import User from '../../interfaces/User';
import ExperienceEntry from '../../interfaces/ExperienceEntry';

export const jsonToJobs = (data: any) => {
    if (!data) {
        return;
    }
    const processedData: Job[] = data
        // enforce uniqueness
        .map((object) => {
            return {
                // extract the info out from the returned api job object
                company_id: object.company_id,
                company_title: object.company_title,
                company_logo_filename: object.company_logo_filename,
                employee_count: object.employee_count,
                id: object.id,
                indeed_uid: object.indeed_uid,
                industry: object.industry,
                job_term: toTitleCaseFromKababCase(object.job_term),
                job_term_id: object.job_term_id,
                job_type: object.job_type,
                job_type_id: object.job_type_id,
                link: object.link,
                loc_term: toTitleCaseFromKababCase(object.loc_term),
                loc_term_id: object.loc_term_id,
                location: object.location,
                post_date: new Date(object.post_date),
                salary: object.salary,
                title: object.title,
                e_verified: object.e_verified,
                uscis_name: object.uscis_name,
                everify_name: object.everify_name,
                tagging_score: object.tagging_score,
                full_description: object.full_description,
                exp_term: object.exp_term,
            } as Job;
        });
    return processedData;
};

export const jsonToCompany = (data: any) => {
    const h1bDetails: H1BDetail[] = [];
    if (data.h1b_details) {
        data.h1b_details.forEach((detail) => {
            const index = h1bDetails.findIndex((d) => d.fiscal_year === detail.fiscal_year);
            if (index >= 0) {
                h1bDetails[index].initial_approval_count += detail.initial_approval_count;
                h1bDetails[index].initial_denial_count += detail.initial_denial_count;
                h1bDetails[index].continuing_approval_count += detail.continuing_approval_count;
                h1bDetails[index].continuing_denial_count += detail.continuing_denial_count;
            } else {
                h1bDetails.push({ ...detail } as H1BDetail);
            }
        });
    }

    return {
        id: data.id,
        title: data.title,
        address: data.address,
        employee_count: data.employee_count,
        industry: data.industry,
        search_term: data.search_term,
        e_verified: data.e_verified,
        hiring_link: data.hiring_link,
        logo_filename: data.logo_filename,
        uscis_name: data.uscis_name,
        everify_name: data.everify_name,
        h1b_details: h1bDetails,
        description: data.description,
    } as Company;
};

export const jsonToUser = (data: any) => {
    return {
        id: data.id,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        preferred_roles: data.roles,
        locations: data.locations,
        user_preference_id: data.user_preference_id,
        saved_jobs: data.saved_jobs,
        resume_filename: data.resume_filename,
        resume_id: data.resume_id,
        profile_image_id: data.profile_image,
        profile_image_filename: data.profile_image_filename,
        referrer_id: data.referrer_id,
    } as User;
};

export const jsonToExperience = (data: any) => {
    return {
        id: data.experience_id,
        experience_type: data.experience_type,
        organization_name: data.organization_name,
        start_time: data.start_time,
        end_time: data.end_time,
        description: data.description,
        title: data.title,
        location: data.location,
    } as ExperienceEntry;
};

export const getPayload = (data: any) => {
    return {
        body: {
            data: data,
        },
    };
};

export const getAuthToken = async (payload: any) => {
    payload['headers'] = {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    };
    return payload;
};

export const toBase64 = (file: any) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
};

export const downloadBase64File = (contentBase64, fileName) => {
    const extension = fileName.split('.').at(-1);
    const linkSource = `data:application/${extension};base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
};

import React, { useState } from 'react';
import * as AuthHelper from '../helpers/AWSHelpers';
import Button from '@material-ui/core/Button';
import {
    UserAuthForm,
    UserAuthFormControl,
    UserAuthFormControlLabel,
    UserAuthFormSubtitle,
    UserAuthIcon,
    UserAuthInputField,
} from '../../shared/UserAuthForm';
import { InputAdornment } from '@material-ui/core';

interface ResetPasswordProps {
    done: any;
    username: string;
}

type ValidationErrorTypes = {
    type: 'code' | 'pwd' | 'confirmpwd' | 'none';
    msg: string;
};

export default function ResetPassword(props: ResetPasswordProps) {
    if (!props.username) {
        console.error("username for ResetPassword can't be empty");
    }

    const [code, setCode] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordRetype, setPasswordRetype] = useState('');
    const [validationError, setValidationError] = useState<ValidationErrorTypes>({ type: 'none', msg: '' });
    const [error, setError] = useState('');

    const handleChangeCode = (val: any) => setCode(val.target.value);
    const handleChangePassword = (val: any) => setPassword(val.target.value);
    const handleChangePasswordRetype = (val: any) => setPasswordRetype(val.target.value);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Trigger an alert on form submission
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        AuthHelper.forgotPasswordSubmit(props.username, code, password)
            .then((res) => {
                props.done(props.username);
            })
            .catch((err) => {
                setError(err.message);
            });
    };
    const handleResend = (event: any) => {
        event.preventDefault();
        AuthHelper.forgotPassword(props.username).catch((err) => {
            setError(err.message);
        });
    };

    const validateForm = () => {
        if (!code) {
            setError('Code is missing');
            setValidationError({ type: 'code', msg: 'Code is missing' });
        } else if (!password) {
            setError('Password is missing');
            setValidationError({ type: 'pwd', msg: 'Password is missing' });
        } else if (password && password.length < 8) {
            setError('Minimum length for password should be 8');
            setValidationError({ type: 'pwd', msg: 'Minimum length for password should be 8' });
        } else if (password !== passwordRetype) {
            setError("Password doesn't match");
            setValidationError({ type: 'confirmpwd', msg: "Password doesn't match" });
        } else {
            setValidationError({ type: 'none', msg: '' });
            return true;
        }
        return false;
    };

    return (
        <UserAuthForm>
            <UserAuthFormControl>
                <UserAuthFormSubtitle>
                    Check your email for the verification code and reset your password.
                </UserAuthFormSubtitle>
                <UserAuthInputField
                    autoFocus
                    fullWidth
                    id="code-received"
                    variant="outlined"
                    placeholder="Code received"
                    error={!!error && validationError.type === 'code'}
                    helperText={validationError.type === 'code' ? validationError.msg : ''}
                    onChange={handleChangeCode}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/EmailIcon.svg" />
                            </InputAdornment>
                        ),
                    }}
                />
                <UserAuthInputField
                    autoFocus
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Please enter your new password"
                    id="new-password"
                    variant="outlined"
                    error={!!error && validationError.type === 'pwd'}
                    helperText={validationError.type === 'pwd' ? validationError.msg : ''}
                    onChange={handleChangePassword}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/passwordIcon.svg" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {showPassword ? (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/hidepasswordicon.svg"
                                        onClick={handleClickShowPassword}
                                    />
                                ) : (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/showpasswordicon.svg"
                                        onClick={handleClickShowPassword}
                                    />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <UserAuthInputField
                    autoFocus
                    fullWidth
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirm-new-password"
                    variant="outlined"
                    placeholder="Please confirm your new password"
                    error={!!error && validationError.type === 'confirmpwd'}
                    helperText={validationError.type === 'confirmpwd' ? validationError.msg : ''}
                    onChange={handleChangePasswordRetype}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/passwordIcon.svg" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {showConfirmPassword ? (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/hidepasswordicon.svg"
                                        onClick={handleClickShowConfirmPassword}
                                    />
                                ) : (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/showpasswordicon.svg"
                                        onClick={handleClickShowConfirmPassword}
                                    />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <Button fullWidth variant="contained" color="primary" type="submit" onClick={handleSubmit}>
                    Reset Password
                </Button>
                <UserAuthFormControlLabel
                    className="sign-in-now"
                    control={
                        <Button className="no-transform" color="primary" onClick={handleResend}>
                            Resend
                        </Button>
                    }
                    label="Didn't receive it?"
                    labelPlacement={'start'}
                />
            </UserAuthFormControl>
        </UserAuthForm>
    );
}

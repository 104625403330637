import { RequestDetailsInterface } from './RequestDetails.interface';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Container } from 'react-bootstrap';
import { FolderOpen } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';

import { truncateText } from '../../../../utils/textHelper';
import { ReferralRequestStatus } from '../../../../enums/ReferralRequestStatus';
import { ReferralRequestDetailModel } from '../../../../interfaces/referral/ReferralRequestDetailModel';
import CompanyLogo from '../../../shared/CompanyLogo';
import * as APIs from '../../../apis/APIs';
import { ReferralPostDetailModel } from '../../../../interfaces/referral/ReferralPostDetailModel';
import { downloadBase64File } from '../../../apis/helpers';
import CheckBox from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        marginTop: 100,
    },
    requestDetailSection: {
        padding: '30px',
        minHeight: 719,
        borderRadius: 10,
        backgroundColor: 'white',
    },
    personalInfoWrap: {
        height: 60,
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
    },
    avatar: {
        border: '0.5px solid grey',
    },
    avatarWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    nameWrap: {
        marginLeft: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: 16,
    },
    buttonsWrap: {},
    tagsWrap: {
        marginTop: 20,
    },
    descriptionLarge: {
        marginTop: 20,
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: 16,
    },
    description: {
        color: '#122534',
        fontWeight: 400,
        fontSize: 14,
    },
    attachmentFiles: {
        marginTop: 10,
        color: '#122534',
        fontWeight: 400,
        fontSize: 14,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    descriptionUrl: {
        color: '#165EBF',
        fontWeight: 400,
        fontSize: 14,
    },
    descriptionFile: {
        color: '#165EBF',
        fontWeight: 400,
        fontSize: 14,
        textDecoration: 'underline',
    },
    confirmDialogHeader: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '32px',
        color: '#0B132C',
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: 'Roboto',
    },
    confirmDialogContent: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        color: '#000000',
        fontFamily: 'Roboto',
    },
    confirmDialogButtonWrap: {
        marginTop: 60,
        float: 'right',
    },
    confirmDialogButtonLeft: {
        textTransform: 'none',
        borderRadius: '10px',
        fontFamily: 'Roboto',
        paddingLeft: 16,
        paddingRight: 16,
    },
    confirmDialogButtonRight: {
        textTransform: 'none',
        borderRadius: '10px',
        fontFamily: 'Roboto',
        paddingLeft: 16,
        paddingRight: 16,
    },
    actionItemButtonRed: {
        fontFamily: 'Roboto',
        textTransform: 'none',
        marginRight: 10,
    },
    actionItemButtonPrimary: {
        fontFamily: 'Roboto',
        textTransform: 'none',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 'auto',
        marginBottom: 'auto',
        height: 36,
        borderRadius: '10px',
        border: '1px',
        color: '#FFFFFF',
        backgroundColor: '#165EBF',
    },
    requestDetailHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    requestDetailHeaderBtnGroup: {
        display: 'flex',
    },
    centerItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    avatarNameWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    btnGroupErrorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        justifyContent: 'center',
    },
    errorMessage: {
        color: 'red',
    },
}));

const AttachedFiles = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: start;
`;

const ConfirmDialogContainer = styled(Container)`
    padding: 40px;
    .MuiDialogTitle-root {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 125% */

        /* text/main */

        color: #0b132c;
        padding: 16px 0;
    }
    .MuiDialogContent-root {
        color: #000000;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        padding: 16px 0px;
    }
    .MuiTypography-colorTextSecondary,
    .MuiTypography-body1 {
        color: #000000;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
    }
`;

const ReferralConfirmation = styled(FormGroup)`
    display: flex;
    flex-flow: row;
    margin-top: 16px;
`;

const RequestDetails = ({
    curType,
    selectedReq,
    changeReqStatus,
    showCompanyHeader,
    userId,
    isLoading,
    errorMessage,
}: RequestDetailsInterface) => {
    const classes = useStyles();
    const [dialogType, setDialogType] = useState('');
    const [open, setOpen] = useState(false);
    const [originalPost, setOriginalPost] = useState<ReferralPostDetailModel | undefined>(null);

    const handleClose = () => {
        setOpen(false);
    };

    const handleRefer = (req: ReferralRequestDetailModel) => {
        changeReqStatus(req, ReferralRequestStatus.Referred);
        setOpen(false);
    };

    const handleDecline = (req: ReferralRequestDetailModel) => {
        setOpen(false);
        changeReqStatus(req, ReferralRequestStatus.Rejected);
    };

    const handleWithdraw = (req: ReferralRequestDetailModel) => {
        setOpen(false);
        changeReqStatus(req, ReferralRequestStatus.Unfinished);
    };

    interface ConfirmDialogProps {
        open: boolean;
        type: string;
    }

    useEffect(() => {
        if (selectedReq && showCompanyHeader && userId) {
            APIs.ReferralPostReadGet(userId, selectedReq.referral_post_id)
                .then((data: ReferralPostDetailModel) => {
                    setOriginalPost(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [selectedReq]);

    const ConfirmDialog = (props: ConfirmDialogProps) => {
        const [agree, setAgree] = useState(false);

        const agreeRefer = (req: ReferralRequestDetailModel) => {
            if (agree) {
                handleRefer(req);
            }
        };

        return (
            <Dialog
                maxWidth={false}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ConfirmDialogContainer>
                    <DialogTitle id="alert-dialog-title" disableTypography={true}>
                        {props.type == 'refer'
                            ? 'Acknowledge Confirmation'
                            : props.type == 'decline'
                            ? 'Decline Confirmation'
                            : 'Withdraw Confirmation'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.type == 'refer' ? (
                                <div>
                                    <div style={{ marginLeft: 0 }}>
                                        We’ll notify {selectedReq.first_name} {selectedReq.last_name} via email if you
                                        acknowledge the request.
                                    </div>
                                    <ReferralConfirmation>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={agree}
                                                    onChange={() => setAgree(!agree)}
                                                    color="primary"
                                                />
                                            }
                                            label={`I have referred/will refer the candidate in the company’s system.`}
                                        />
                                    </ReferralConfirmation>
                                </div>
                            ) : props.type == 'decline' ? (
                                `We’ll notify ${selectedReq.first_name} ${selectedReq.last_name} via email when you decline the request.`
                            ) : (
                                'Are you sure you want to withdraw your referral request?'
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <Row className={classes.confirmDialogButtonWrap}>
                        <DialogActions
                            style={{
                                paddingRight: 40,
                                marginBottom: 0,
                            }}
                        >
                            <Button
                                onClick={
                                    props.type === 'refer'
                                        ? () => agreeRefer(selectedReq)
                                        : props.type === 'decline'
                                        ? () => handleDecline(selectedReq)
                                        : () => handleWithdraw(selectedReq)
                                }
                                autoFocus
                                disabled={
                                    (!agree && props.type === 'refer') ||
                                    (props.type === 'withdraw' && selectedReq.status != 'pending')
                                }
                                className={classes.confirmDialogButtonLeft}
                                color="primary"
                                variant="contained"
                            >
                                {props.type == 'refer' ? 'Confirm' : props.type === 'decline' ? 'Decline' : 'Withdraw'}
                            </Button>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={handleClose}
                                autoFocus
                                className={classes.confirmDialogButtonRight}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Row>
                </ConfirmDialogContainer>
            </Dialog>
        );
    };
    const headerWithAvatarAndName = (selectedReq: ReferralRequestDetailModel) => (
        <Row className={classes.personalInfoWrap}>
            <div className={classes.avatarNameWrapper}>
                <div className={classes.avatarWrap}>
                    <Avatar
                        alt="avatar"
                        style={{ color: 'white', backgroundColor: '#165ebf', height: 60, width: 60 }}
                        className={classes.avatar}
                    >
                        {selectedReq.first_name.charAt(0)}
                        {selectedReq.last_name.charAt(0)}
                    </Avatar>
                </div>
                <div className={classes.nameWrap}>
                    {selectedReq.first_name} {selectedReq.last_name}
                </div>
            </div>
            <div className={classes.btnGroupErrorWrapper}>
                {selectedReq.status == 'pending' && (
                    <div className={classes.buttonsWrap}>
                        <Button
                            className={`button ${classes.actionItemButtonRed}`}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                // changeReqStatus(selectedReq, 'declined');
                                setDialogType('deline');
                                setOpen(true);
                            }}
                        >
                            Decline
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setDialogType('refer');
                                setOpen(true);
                            }}
                        >
                            Refer
                        </Button>
                    </div>
                )}
                {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
            </div>
        </Row>
    );

    const headerWithCompanyInfo = (selectedReq: ReferralRequestDetailModel) => (
        <Row className={classes.personalInfoWrap}>
            <Col
                md="1"
                style={{
                    padding: 0,
                }}
                className={classes.requestDetailHeader}
            >
                <CompanyLogo size={40} companyLogoFilename={originalPost.company_logo_filename} />
            </Col>
            <Col md="6" className={classes.requestDetailHeader}>
                <Row>{originalPost.title}</Row>
                <Row>
                    <span>{originalPost.company_title}</span>&nbsp;|&nbsp;<span>{originalPost.job_location}</span>
                </Row>
            </Col>
            <Col className={classes.requestDetailHeaderBtnGroup}>
                {/* TODO: remove comment when we support withdraw  */}
                {selectedReq.status == 'pending' && (
                    <div className={classes.buttonsWrap}>
                        {selectedReq.referee_id === userId ? (
                            <Button
                                className={`button ${classes.actionItemButtonRed}`}
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                    setDialogType('withdraw');
                                    setOpen(true);
                                }}
                            >
                                Withdraw
                            </Button>
                        ) : null}
                    </div>
                )}
                {/* <Button
                    className={`button ${classes.actionItemButtonPrimary}`}
                    variant="outlined"
                    onClick={() => {
                        setDialogType('refer');
                        setOpen(true);
                    }}
                >
                    Original Post
                </Button> */}
            </Col>
        </Row>
    );

    return (
        <React.Fragment>
            {isLoading && (
                <Row className={classes.requestDetailSection}>
                    <div className={classes.centerItem}>
                        <CircularProgress />
                    </div>
                </Row>
            )}
            {!isLoading && (
                <Row className={classes.requestDetailSection}>
                    {selectedReq ? (
                        <Col>
                            {showCompanyHeader && userId && originalPost
                                ? headerWithCompanyInfo(selectedReq)
                                : headerWithAvatarAndName(selectedReq)}
                            {/* TODO: add experience level */}
                            {/* <Row className={classes.tagsWrap}>
                            <div
                                style={{
                                    borderRadius: '4px',
                                    color: '#FFFFFF',
                                    backgroundColor: '#165EBF',
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    fontSize: 12,
                                }}
                            >
                                New Grad
                            </div>
                            <div
                                style={{
                                    marginLeft: 10,
                                    borderRadius: '4px',
                                    color: '#FFFFFF',
                                    backgroundColor: '#165EBF',
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    fontSize: 12,
                                }}
                            >
                                1 year experience
                            </div>
                        </Row> */}
                            <Row className={classes.descriptionLarge}>Email Address</Row>
                            <Row className={classes.description}>{selectedReq.email}</Row>
                            <Row className={classes.descriptionLarge}>Referral Position Link(s)</Row>
                            {selectedReq &&
                                selectedReq.job_links &&
                                selectedReq.job_links.map((url, i) => (
                                    <Row className={classes.descriptionUrl} key={i}>
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: '#165ebf' }}
                                        >
                                            {truncateText(url)}
                                        </a>
                                    </Row>
                                ))}
                            <Row className={classes.descriptionLarge}>Self-introduction</Row>
                            <Row className={classes.description}>
                                {selectedReq.self_intro ? selectedReq.self_intro : 'N/A'}
                            </Row>
                            {selectedReq.attachments && Object.keys(selectedReq.attachments).length > 0 && (
                                <Row className={classes.descriptionLarge}>Attached Files</Row>
                            )}
                            <AttachedFiles>
                                {Object.entries(selectedReq.attachments).map(([key, value], idx) => (
                                    <Row
                                        key={idx}
                                        onClick={() => downloadBase64File(value, key)}
                                        className={classes.attachmentFiles}
                                    >
                                        <AttachFileIcon className="mr-1" color="primary" />
                                        {key}
                                    </Row>
                                ))}
                            </AttachedFiles>
                        </Col>
                    ) : (
                        <Container style={{ margin: 'auto auto auto auto' }}>
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <FolderOpen style={{ transform: 'scale(1.9)' }} color="primary"></FolderOpen>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <Box
                                        color="#122534"
                                        style={{
                                            textTransform: 'none',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            marginTop: '10px',
                                        }}
                                    >
                                        Details are not available yet
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </Row>
            )}
            {selectedReq && <ConfirmDialog open={open} type={dialogType} />}
        </React.Fragment>
    );
};

export default RequestDetails;

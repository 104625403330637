import React from 'react';
import { FormControl, InputBase, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const BootstrapInputBase = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '0.5px solid #0B132C',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: 'rgba(2, 117, 216, 0.25) 0 0 0 0.2rem',
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

export interface BootstrapInputProps {
    id: string;
    label: string;
    value?: any;
    disabled?: boolean;
    onChange?: any;
    params?: object;
}

const BootstrapInput = ({ id, label, value, disabled, onChange, params }: BootstrapInputProps) => {
    return (
        <FormControl fullWidth variant="standard">
            <InputLabel
                htmlFor={id}
                style={{
                    transform: 'translate(0, 1.5px) scale(1)',
                    transformOrigin: 'top left',
                }}
            >
                {label}
            </InputLabel>
            <BootstrapInputBase fullWidth id={id} value={value} onChange={onChange} disabled={disabled} {...params} />
        </FormControl>
    );
};

export default BootstrapInput;

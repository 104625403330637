export const RESUME_FILESIZE_LIMIT = 2097152; // in bytes, this is 2MB
export const H1B_DISPLAY_TEXT = 'H-1B';
export const EVERIFIED_DISPLAY_TEXT = 'E-Verified';
export const SELECT_ALL_TEXT = 'Select All';

export const TYPE_ROLES_MAP = {
    full_time: 'Full-time',
    internship: 'Internship',
    part_time: 'Part-time',
    contract: 'Contract',
};
export const TYPE_ROLES_MAP_KEY_LOOKUP = {
    'Full-time': 'full_time',
    Internship: 'internship',
    'Part-time': 'part_time',
    Contract: 'contract',
};

export const JOB_TABS_MAP = [
    {
        name: 'Software Engineer',
        options: ['Software Development Engineer', 'Frontend Engineer', 'Backend Engineer', 'Fullstack Engineer'],
    },
    {
        name: 'Data Scientist',
        options: ['Machine Learning Engineer', 'Data Engineer', 'Data Scientist', 'Data Analyst'],
    },
];

export const FILTERS_MAP = [
    {
        type: 'Job Title',
        options: [
            'Software Development Engineer',
            'Frontend Engineer',
            'Backend Engineer',
            'Fullstack Engineer',
            'Machine Learning Engineer',
            'Data Engineer',
            'Data Scientist',
            'Data Analyst',
        ],
    },
    {
        type: 'Job Type',
        options: ['Full-time', 'Internship', 'Part-time', 'Contract'],
    },
    {
        type: 'Sponsor',
        options: ['H-1B', 'E-Verified'],
    },
    {
        type: 'Location',
        options: [
            'Chicago',
            'Washington DC',
            'New York',
            'Boston',
            'Dallas',
            'Charlotte',
            'San Francisco',
            'Atlanta',
            'Austin',
            'Seattle',
            'San Diego',
            'Los Angeles',
        ],
    },
    {
        type: 'Industry',
        options: [],
    },
    {
        type: 'Level',
        options: [],
    },
    // {
    //     type: 'Company',
    //     options: [],
    // },
];
export const ROLES_MAP = {
    '0': 'Software Development Engineer',
    '1': 'Frontend Engineer',
    '2': 'Backend Engineer',
    '3': 'Fullstack Engineer',
    '4': 'Machine Learning Engineer',
    '5': 'Data Engineer',
    '6': 'Data Scientist',
    '7': 'Data Analyst',
};

export const DEFAULT_LOCATIONS_MAP = {
    '0': 'Chicago',
    '1': 'Washington DC',
    '2': 'New York',
    '3': 'Boston',
    '4': 'Dallas',
    '5': 'Charlotte',
    '6': 'San Francisco',
    '7': 'Atlanta',
    '8': 'Austin',
    '9': 'Seattle',
    '10': 'San Diego',
    '11': 'Los Angeles',
};

export const QnAText = {
    everify: [
        'You are currently on Post-Completion OPT, but requiring an OPT Extension soon.',
        'You are currently on STEM OPT while looking for a new employer. ',
    ],
    h1b: [
        'You are currently on Post-Completion OPT, but are not eligible to apply for an OPT Extension.',
        'You are currently on STEM OPT, which is ending soon.',
    ],
};

export function selectToBoolean(option: string) {
    switch (option) {
        case 'True':
            return true;
        case 'False':
            return false;
        default:
            return null;
    }
}

export function toArray(data: any) {
    const retVal: any[] = [];
    Object.keys(data).map((key, index) => {
        retVal.push({ id: key, value: data[key] });
    });
    return retVal;
}

export const toKababCaseWithPlusSign = (string: string) => {
    return string.replace(/\s+/g, '+').toLowerCase();
};

export function toArrayLiteral(data: any, keepKey = false) {
    const retVal: any[] = [];
    if (keepKey) {
        Object.keys(data).map((key, index) => {
            retVal.push({ id: key, value: data[key] });
        });
    } else {
        Object.keys(data).map((key, index) => {
            retVal.push({ id: toKababCaseWithPlusSign(data[key]), value: data[key] });
        });
    }
    return retVal;
}

export const BOOLEAN = {
    TRUE: '1',
    FALSE: '0',
};

export const toTitleCaseFromKababCase = (string: string, splitOn = '+') => {
    if (!string) {
        return string;
    }
    if (string === 'washington+dc') {
        return 'Washington DC';
    }
    const sentence = string.toLowerCase().split(splitOn);
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
};

export const validateEmailFormat = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
};

const pad2 = (n) => {
    return (n < 10 ? '0' : '') + n;
};

export const dateToFormatString = (date: Date) => {
    const month = pad2(date.getMonth() + 1);
    const day = pad2(date.getDate());
    const year = date.getFullYear();
    return year + '/' + month + '/' + day;
};

export const COMPANY_LOGO_PATH = 'https://s3.amazonaws.com/aitou.io.bucket/public/';

import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import * as APIs from '../../apis/APIs';
import ReferralDashboardList from './ReferralDashboardList';
import ReferralApplicationDetailPage from './ReferralApplicationDetailPage';
import { ReferralRequestListItemModel } from '../../../interfaces/referral/ReferralRequestListItemModel';
import { ToggleButton } from '@material-ui/lab';
import { ReferralRequestDetailModel } from '../../../interfaces/referral/ReferralRequestDetailModel';
import { ReferralPostDetailModel } from '../../../interfaces/referral/ReferralPostDetailModel';
import { ReferralTrackerStatusRow } from '../../shared/ReferralSharedComponents';
import { ReferralRequestStatus } from '../../../enums/ReferralRequestStatus';

const useStyles = makeStyles((theme: Theme) => ({
    fullScreenOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 9999,
        overflowX: 'hidden',
        overflowY: 'scroll',
        backgroundColor: '#f3f2ef',
    },
    centerItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
}));

interface RefererTrackerProps {
    userId: any;
    data: ReferralRequestListItemModel[] | undefined;
    isShowApplicationPage: boolean;
    setIsShowApplicationPage: (val: boolean) => void;
}

const RefererTracker = (props: RefererTrackerProps) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { isShowApplicationPage, setIsShowApplicationPage } = props;
    const classes = useStyles();
    const [selectedRequest, setSelectedRequest] = useState<ReferralRequestDetailModel | undefined>(null);
    const [referralPost, setReferralPost] = useState<ReferralPostDetailModel | undefined>(null);

    const getFilteredData = (currentTabIndex: number) => {
        switch (currentTabIndex) {
            case 0: // Track tab
                return props.data.filter(
                    // Ensure referral exists before accessing 'status'
                    (ref: ReferralRequestListItemModel) => ref && ref.status !== ReferralRequestStatus.Unfinished,
                );
            case 2: // Drafts tab
                return props.data.filter(
                    (ref: ReferralRequestListItemModel) => ref && ref.status === ReferralRequestStatus.Unfinished,
                );
            default:
                return props.data;
        }
    };

    const openDetailPageHandler = (request: ReferralRequestListItemModel) => {
        setIsShowApplicationPage(true);
        APIs.ReferralRequestRead(props.userId, request.id)
            .then((data: ReferralRequestDetailModel) => {
                setSelectedRequest(data);
                APIs.ReferralPostReadGet(props.userId, data.referral_post_id)
                    .then((data: ReferralPostDetailModel) => {
                        setReferralPost(data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const clearData = () => {
        setSelectedRequest(null);
        setReferralPost(null);
    };
    const closeDetailPageHandler = () => {
        setIsShowApplicationPage(false);
        clearData();
    };

    return (
        <React.Fragment>
            {isShowApplicationPage && (
                <div>
                    <ReferralApplicationDetailPage
                        userId={props.userId}
                        data={selectedRequest}
                        closeHandler={closeDetailPageHandler}
                        referralPost={referralPost}
                    />
                </div>
            )}

            {isLoading && (
                <div className={classes.centerItem}>
                    <CircularProgress />
                </div>
            )}
            {!isLoading && !isShowApplicationPage && (
                <React.Fragment>
                    {/* TODO: Until we onboard with track functions */}
                    <ReferralTrackerStatusRow>
                        <ToggleButton selected={currentTabIndex === 0} value={0} onClick={() => setCurrentTabIndex(0)}>
                            Track
                        </ToggleButton>
                        <ToggleButton
                            selected={currentTabIndex === 1}
                            onClick={() => setCurrentTabIndex(1)}
                            value={1}
                            disabled
                        >
                            Saved(Coming soon)
                        </ToggleButton>
                        <ToggleButton selected={currentTabIndex === 2} onClick={() => setCurrentTabIndex(2)} value={2}>
                            Drafts
                        </ToggleButton>
                    </ReferralTrackerStatusRow>
                    <ReferralDashboardList
                        data={getFilteredData(currentTabIndex)}
                        openDetailPageHandler={openDetailPageHandler}
                        currentTabIndex={currentTabIndex}
                    ></ReferralDashboardList>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
export default RefererTracker;

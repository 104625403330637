import React, { useState } from 'react';
import { Avatar, IconButton } from '@material-ui/core';
import { userProfilePatch } from '../../apis/users';
import { EditOutlined } from '@material-ui/icons';
import { ProfileResumeSection } from './ProfileResumeSection';
import { ProfileEditModal } from './ProfileEditModal';
import styled from 'styled-components';
import { ProfileAvartarImg, ProfileAvatar, ProfileHeaderContainer, ProfileUserInfo } from '../../shared/PanelComponent';

const ProfileContentWrap = styled.div`
    margin: 0px auto auto auto;
`;

const PlaceholderAvatar = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
`;

const ProfileName = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    /* or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    position: absolute;
`;

const ProfileEmail = styled.div`
    position: absolute;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    color: white;
    padding: 24px;
`;

const ProfileEditViewWrap = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
`;

const NameEditButton = styled(IconButton)`
    padding: 0px !important;
    margin-left: 11px !important;
    border-bottom: 1px solid #165ebf !important;
    color: #165ebf !important;
    border-radius: 0 !important;
`;

const ProfileEmailWrap = styled.div`
    font-family: 'Roboto';
    font-weight: 400;
    margin: 20px auto auto auto;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
`;

export interface ProfileHeaderProps {
    userId: number;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    profileImageFilename: string;
    updateProfileHandler: any;
    resumeFileName: string;
    userCustomId: any;
    resumeId: number;
}

export const ProfileHeader = ({
    userId,
    userEmail,
    userFirstName,
    userLastName,
    profileImageFilename,
    updateProfileHandler,
    resumeFileName,
    userCustomId,
    resumeId,
}: ProfileHeaderProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const getUserDisplayName = () => {
        let fullName = '';
        if (userFirstName || userLastName) {
            fullName = `${userFirstName} ${userLastName}`;
        } else if (userEmail) {
            fullName = userEmail;
        } else {
            fullName = 'User';
        }

        return fullName;
    };

    const updateUserProfile = async (props) => {
        setIsLoading(true);
        props['id'] = userId;

        userProfilePatch(props)
            .then(() => {
                updateProfileHandler(props);
            })
            .catch(() => {
                console.log('Some error has occurred when updating profile info.');
            })
            .finally(() => {
                setIsLoading(false);
                // window.location.reload();
            });
    };

    const onSubmitHandler = ({ profileImageFilename, profileImageContent, firstName, lastName }) => {
        updateUserProfile({
            profileImageFilename: profileImageFilename,
            profileImageContent: profileImageContent,
            firstName: firstName,
            lastName: lastName,
        });
        setIsEditing(false);
        setIsModalOpen(false);
    };

    return (
        <ProfileHeaderContainer>
            <ProfileUserInfo>
                <ProfileContentWrap>
                    <ProfileAvatar>
                        {profileImageFilename ? (
                            <ProfileAvartarImg
                                src={`https://s3.amazonaws.com/aitou-users-public-attachments/${userId}/${profileImageFilename}`}
                            />
                        ) : userFirstName ? (
                            <PlaceholderAvatar>
                                <svg
                                    width="80"
                                    height="80"
                                    viewBox="0 0 80 80"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="40" cy="40" r="40" fill="#858995" />
                                </svg>
                                <ProfileName>
                                    {`${userFirstName.substring(0, 1).toUpperCase()} ${userLastName
                                        .substring(0, 1)
                                        .toUpperCase()}`}
                                </ProfileName>
                            </PlaceholderAvatar>
                        ) : (
                            <PlaceholderAvatar>
                                <svg
                                    width="80"
                                    height="80"
                                    viewBox="0 0 80 80"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="40" cy="40" r="40" fill="#858995" />
                                </svg>
                                <ProfileEmail>{userEmail?.split('@')[0]}</ProfileEmail>
                            </PlaceholderAvatar>
                        )}
                    </ProfileAvatar>
                </ProfileContentWrap>
                <ProfileEditViewWrap>
                    {getUserDisplayName()}
                    <NameEditButton
                        color="primary"
                        onClick={() => {
                            setIsEditing(true);
                            setIsModalOpen(true);
                        }}
                        disabled={isEditing}
                    >
                        <EditOutlined></EditOutlined>
                    </NameEditButton>
                </ProfileEditViewWrap>
                <ProfileEmailWrap>{userEmail}</ProfileEmailWrap>
            </ProfileUserInfo>
            <ProfileResumeSection
                resumeFileName={resumeFileName}
                userCustomId={userCustomId}
                resumeId={resumeId}
            ></ProfileResumeSection>
            {isModalOpen && (
                <ProfileEditModal
                    isLoading={isLoading}
                    isOpen={isModalOpen}
                    setIsModalOpen={(b) => {
                        setIsModalOpen(b);
                        setIsEditing(b);
                    }}
                    userFirstName={userFirstName}
                    userLastName={userLastName}
                    onSubmitHandler={(props) => onSubmitHandler(props)}
                />
            )}
        </ProfileHeaderContainer>
    );
};

import { Avatar } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ProfileRightSplitWrap = styled(Col)`
    font-family: 'Roboto';
    margin-left: 20px !important;
    padding: 0;
`;

export const ProfileLeftSplitWrap = styled.div`
    width: 300px;
`;

export const ProfileSection = styled(Container)`
    font-family: 'Roboto';
    background: #f3f4f6;
    padding-bottom: 50px;
`;

export const ProfileHeaderContainer = styled.div`
    height: calc(100vh - 380px);
`;

export const ProfileContainer = styled(Container)`
    min-height: calc(100vh - 60px);
`;

export const ProfileRow = styled(Row)`
    padding-top: 30px;
    position: relative;
`;

export const ProfileUserInfo = styled.div`
    border-radius: 10px;
    background-color: white;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 44px;
`;

export const ProfileAvatar = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`;

export const ProfileAvartarImg = styled(Avatar)`
    width: 80px !important;
    height: auto !important;
`;

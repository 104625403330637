import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { Section } from '../../components/landing/sections/StyledSectionComponents';

const IntroSectionContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: center;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

const IntroSection = styled(Section)``;

const IntroWrapper = styled(Row)`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0;

    @media (max-width: 960px) {
        flex-direction: column;
        gap: 48px;
    }
`;

const IntroTextWrapper = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const IntroTitle = styled.div`
    color: #165ebf;
    font-size: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
    @media (max-width: 960px) {
        font-size: 32px;
        font-weight: 600;
    }
`;

const IntroParagraph = styled.div`
    color: var(--text-black, #122534);
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const IntroImgWrapper = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 500px;
    max-width: 100%;

    @media (max-width: 960px) {
        justify-content: center;
    }
`;

const IntroImg = styled.img`
    max-width: 100%;
`;

const ServiceSectionContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-flow: column wrap;
    background: #fbfbfb;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

const ServiceSection = styled(Section)``;

const ServiceSectionTitle = styled.div`
    color: #165ebf;
    text-align: center;
    font-size: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 48px;
    @media (max-width: 960px) {
        font-size: 24px;
        font-weight: 600;
    }
`;

const ServicesWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 160px;
    @media (max-width: 960px) {
        gap: 24px;
    }
`;

const ServiceContainer = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin: 0 15.5px;
`;

const ServiceTitle = styled.div`
    color: #165ebf;
    text-align: center;
    font-size: 24px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
    @media (max-width: 960px) {
        font-size: 20px;
        margin-bottom: 10px;
    }
`;

const ServiceSubTitle = styled.div`
    color: #858995;
    text-align: center;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
    @media (max-width: 960px) {
        margin-bottom: 16px;
    }
`;

const ServiceImg = styled.img``;

const StatsSectionContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-flow: column wrap;
    background: #165ebf;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

const StatsSection = styled(Section)``;

const StatsTitle = styled.div`
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
    @media (max-width: 960px) {
        font-size: 32px;
        font-weight: 600;
    }
`;

const StatsSubtitle = styled.div`
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 960px) {
        font-size: 20px;
    }
`;

const StatsNumbers = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 80px;
    justify-content: center;
    align-items: center;
    gap: 48px;
    @media (max-width: 960px) {
        margin-top: 48px;
        gap: 24px;
    }
`;

const StatsLabel = styled.div`
    color: #fff;
    text-align: center;
    /* Heading 4 */
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const StatsNumber = styled.div`
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
    @media (max-width: 960px) {
        font-size: 32px;
        font-weight: 600;
    }
`;

const StatsDivider = styled(Divider)``;

const JobReferralsData = styled.div`
    width: 255px;
    margin-right: 15px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`;

const CompaniesData = styled.div`
    width: 255px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`;

const AwardsSectionContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-flow: column wrap;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

const AwardsSection = styled(Section)``;

const AwardsTitle = styled.div`
    color: #165ebf;
    font-size: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    @media (max-width: 960px) {
        font-size: 24px;
        font-weight: 600;
    }
`;

const AwardsSubtitle = styled.div`
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
`;

const AwardsImg = styled.img`
    width: 1040px;
    max-width: 100%;
`;

const TeamSectionContainer = styled(Container)`
    margin-top: 120px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`;

const TeamSectionTitle = styled.div`
    margin-bottom: 80px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    /* identical to box height, or 110% */

    /* Text/Black */

    color: #122534;
`;

const TeamInfoList = styled(Row)`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const TeamInfoWrapper = styled(Col)`
    margin-right: 60px;
    margin-bottom: 30px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`;

const TeamInfoAvatar = styled.div`
    border-radius: 50%;
    margin-bottom: 16px;
`;

const TeamInfoName = styled.div`
    margin-bottom: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    color: #000000;
`;

const FeedbackSection = styled(Section)``;

const FeedbackSectionContainer = styled(Container)`
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    flex-flow: column wrap;
    background: #165ebf;
    color: #ffffff;
`;

const FeedbackSectionTitle = styled.div`
    font-size: 24px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
`;

const FeedbackActionButton = styled.div`
    padding: 12px 32px;
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary/Primary Blue */

    color: #165ebf;
    &:hover {
        color: black;
        background: white;
    }
`;

const FeedbackSectionText = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 140% */

    display: flex;
    align-items: center;

    /* Text/white */

    color: #ffffff;
`;

const About = () => {
    ReactGA.initialize('UA-180746720-1');
    ReactGA.pageview('/about');
    const theme = useTheme();
    const useMobileDisplay = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <IntroSectionContainer fluid>
                <IntroSection>
                    <IntroWrapper>
                        <IntroTextWrapper>
                            <IntroTitle>About Us</IntroTitle>
                            <IntroParagraph>
                                {`AiTou filters through companies and finds those that provide reliable referral services and employment authorization visas (CPT, OPT/OPT-Extension, and H-1B).`}
                                <br />
                                <br />
                                {`International students and job seekers can effectively gather the latest job postings from various job platforms, and employers can push their offers and updates to prospective international employees.`}
                            </IntroParagraph>
                        </IntroTextWrapper>
                        <IntroImgWrapper>
                            <IntroImg src="https://s3.amazonaws.com/aitou.io.bucket/images/about.svg"></IntroImg>
                        </IntroImgWrapper>
                    </IntroWrapper>
                </IntroSection>
            </IntroSectionContainer>
            <ServiceSectionContainer fluid>
                <ServiceSection>
                    <ServiceSectionTitle>What we provide</ServiceSectionTitle>
                    <ServicesWrapper>
                        <ServiceContainer>
                            <ServiceTitle>Sponsor Checker</ServiceTitle>
                            <ServiceSubTitle>Check Company Sponsorship History</ServiceSubTitle>
                            <ServiceImg src="https://s3.amazonaws.com/aitou.io.bucket/images/sponsor_checker.svg"></ServiceImg>
                        </ServiceContainer>
                        <ServiceContainer>
                            <ServiceTitle>Job Referral (Coming Soon)</ServiceTitle>
                            <ServiceSubTitle>Find Job Referrals</ServiceSubTitle>
                            <ServiceImg src="https://s3.amazonaws.com/aitou.io.bucket/images/job_referral.svg"></ServiceImg>
                        </ServiceContainer>
                    </ServicesWrapper>
                </ServiceSection>
            </ServiceSectionContainer>
            <StatsSectionContainer fluid>
                <StatsSection>
                    <StatsTitle>Some Numbers Matter</StatsTitle>
                    <StatsSubtitle>
                        {useMobileDisplay ? 'Achievements depicted in numbers' : 'Our achievements depicted in numbers'}
                    </StatsSubtitle>
                    <StatsNumbers>
                        <JobReferralsData>
                            <StatsNumber>10000+</StatsNumber>
                            <StatsLabel>H1B Sponsorship Data</StatsLabel>
                        </JobReferralsData>
                        {useMobileDisplay ? (
                            <StatsDivider
                                orientation="horizontal"
                                style={{ backgroundColor: '#fff', opacity: 0.4, width: '100%' }}
                            />
                        ) : (
                            <StatsDivider
                                orientation="vertical"
                                style={{ backgroundColor: '#fff', opacity: 0.4 }}
                                flexItem
                            />
                        )}
                        <CompaniesData>
                            <StatsNumber>5000+</StatsNumber>
                            <StatsLabel>E-Verify Data</StatsLabel>
                        </CompaniesData>
                        {/*{useMobileDisplay ? (*/}
                        {/*    <StatsDivider orientation="horizontal" style={{ color: '#fff', opacity: 0.4, width: '100%' }} />*/}
                        {/*) : (*/}
                        {/*    <StatsDivider orientation="vertical" style={{ color: '#fff', opacity: 0.4 }} flexItem />*/}
                        {/*)}*/}
                        {/*<StatsDivider orientation="vertical"></StatsDivider>*/}
                        {/*<JobPositionsData>*/}
                        {/*    <StatsNumber>100+</StatsNumber>*/}
                        {/*    <StatsLabel>Job Referrals</StatsLabel>*/}
                        {/*</JobPositionsData>*/}
                    </StatsNumbers>
                </StatsSection>
            </StatsSectionContainer>
            <AwardsSectionContainer fluid>
                <AwardsSection>
                    <AwardsTitle>Incubated at Columbia Startup Lab</AwardsTitle>
                    <AwardsSubtitle>
                        We are selected as Columbia University Startup Lab(CSL) 2021-2022 cohort. CSL has been the
                        headquarters to more than 300 Columbia-founded startups and over 400 members.
                    </AwardsSubtitle>
                    <AwardsImg src="https://s3.amazonaws.com/aitou.io.bucket/images/CSL.png"></AwardsImg>
                </AwardsSection>
            </AwardsSectionContainer>
            {/*<TeamSectionContainer>*/}
            {/*    <TeamSectionTitle>Meet our team</TeamSectionTitle>*/}
            {/*    <TeamInfoList md={4}>*/}
            {/*        {teams.map((team, index) => (*/}
            {/*            <TeamInfoWrapper key={index}>*/}
            {/*                <TeamInfoAvatar>*/}
            {/*                    <TeamImg src={team.img}></TeamImg>*/}
            {/*                </TeamInfoAvatar>*/}
            {/*                <TeamInfoName>{team.name}</TeamInfoName>*/}
            {/*                <TeamInfoTitle>{team.title}</TeamInfoTitle>*/}
            {/*                <TeamInfoSchool>{team.school}</TeamInfoSchool>*/}
            {/*            </TeamInfoWrapper>*/}
            {/*        ))}*/}
            {/*    </TeamInfoList>*/}
            {/*</TeamSectionContainer>*/}
            {!useMobileDisplay && (
                <FeedbackSectionContainer fluid>
                    <FeedbackSection>
                        <FeedbackSectionTitle>Interested In Providing Feedback or Join Us?</FeedbackSectionTitle>
                        <FeedbackSectionText>
                            Contact out via Wechat or email by clicking the icons at the right bottom
                        </FeedbackSectionText>
                    </FeedbackSection>
                    {/* // Hide as it is not working */}
                    {/* <FeedbackActionButton onClick={handleClickOpen}>Yes, I want to join!</FeedbackActionButton>
                <ContactUsDialog open={open} onClose={handleClose} /> */}
                </FeedbackSectionContainer>
            )}
        </>
    );
};

export default About;

import React, { useState } from 'react';
import { Pagination } from '@material-ui/lab';

interface ControlledPaginationProps {
    currentPage: number;
    pagesCount: number;
    pagingCallback: any;
    siblingCount?: number;
    handlePageOutOfRange: any;
}

function ControlledPagination(props: ControlledPaginationProps) {
    const { currentPage, pagesCount, pagingCallback } = props;

    return (
        <Pagination
            siblingCount={3}
            count={pagesCount}
            page={currentPage}
            color="primary"
            size="medium"
            onChange={(_e, p) => pagingCallback(p)}
        />
    );
}

ControlledPagination.defaultProps = {
    siblingCount: 1,
};

export default ControlledPagination;

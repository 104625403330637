export enum SortOrder {
    None = 'none',
    PostDate = 'postDate',
    Relevance = 'relevance',
}

export function fromString(value: string): SortOrder {
    switch (value) {
        case 'postDate':
            return SortOrder.PostDate;
        case 'relevance':
            return SortOrder.Relevance;
        default:
            return SortOrder.None;
    }
}

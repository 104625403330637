import React, { useState, useEffect } from 'react';
import * as AuthHelper from '../helpers/AWSHelpers';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    UserAuthForm,
    UserAuthFormControl,
    UserAuthFormSubtitle,
    UserAuthInputFieldFirst,
} from '../../shared/UserAuthForm';

const VerificationInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    .MuiOutlinedInput-input {
        margin-left: 0;
        padding-left: 0;
    }
    @media (max-width: 960px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ActionContainer = styled.div`
    text-align: center;
    .MuiButton-outlined {
        color: #165ebf;
    }
    margin-left: 0;
`;

interface WaitingVerificationProps {
    done: any;
    signInHandler: any;
    username: string;
    password: string;
    setUserIdHandler: any;
}

const useStyles = makeStyles((theme) => ({
    timeWrapper: {
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
    },
    time: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '19px',
        color: '#165ebf',
    },
}));

export default function WaitingVerification(props: WaitingVerificationProps) {
    const classes = useStyles();
    // in all cases, username should be user's email
    if (!props.username) {
        console.error("username for WaitingVerification can't be empty");
    }
    const [isLoading, setIsLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [waitingForCode, setWaitingForCode] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60);
    const [error, setError] = useState('');

    useEffect(() => {
        let interval = null;
        if (isActive && timeLeft !== 0) {
            interval = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setIsActive(false);
            setWaitingForCode(false);
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, timeLeft]);

    const start = () => {
        setIsActive(true);
        setTimeLeft(60);
    };

    const validateCode = () => {
        if (!verificationCode) {
            setError('Code is missing');
        } else if (verificationCode.length < 4) {
            setError('Code is too short.');
        } else {
            return true;
        }
        return false;
    };

    const handleChangeVerificationCode = (val: any) => {
        setVerificationCode(val.target.value);
        setError('');
    };
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!validateCode()) {
            return;
        }
        // async function
        AuthHelper.confirmSignUp(props.username, verificationCode)
            .then((response) => {
                AuthHelper.signIn(props.username, props.password).then((res) => {
                    props.signInHandler(res);
                });
                props.done(response);
            })
            .catch((error) => {
                setError('The code is not correct');
            });
    };

    const handleResend = (event: any) => {
        event.preventDefault();
        setWaitingForCode(true);
        start();
        // async function
        AuthHelper.resendConfirmationCode(props.username)
            .then((response) => {
                console.log('Resent verification code to ' + props.username);
            })
            .catch((error) => {
                setError(`Failed to send verification to ${props.username}`);
            });
    };
    return (
        <UserAuthForm>
            <UserAuthFormControl>
                <UserAuthFormSubtitle>
                    We have sent the verification code. Please check your email.
                </UserAuthFormSubtitle>
                <VerificationInputContainer>
                    <UserAuthInputFieldFirst
                        autoFocus
                        size="small"
                        id="verification"
                        placeholder="Verification Code"
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                        onChange={handleChangeVerificationCode}
                    />
                    <ActionContainer>
                        {!waitingForCode && (
                            <Button variant="outlined" color="primary" onClick={handleResend}>
                                Resend
                            </Button>
                        )}
                        {waitingForCode && (
                            <div className={classes.timeWrapper}>
                                <span className={classes.time}>{timeLeft}</span>
                            </div>
                        )}
                    </ActionContainer>
                </VerificationInputContainer>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    Create Account
                </Button>
            </UserAuthFormControl>
        </UserAuthForm>
    );
}

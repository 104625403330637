import React from 'react';
import { Button } from '@material-ui/core';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ReferralRequestListItemModel } from '../../../interfaces/referral/ReferralRequestListItemModel';
import CompanyLogo from '../../shared/CompanyLogo';
import styled from 'styled-components';
import {
    ActionButtonRow,
    ReferralApplicationCellCol,
    ReferralApplicationCellCompanyImg,
    ReferralApplicationCellCompanyInfo,
    ReferralApplicationCellRow,
    ReferralApplicationPrimaryTitle,
    ReferralApplicationSecondaryTitle,
    ReferralApplicationStatusDot,
    ReferralDashboardListCellBox,
    ReferralStatus,
} from '../../shared/ReferralSharedComponents';
import ControlledPagination from '../../shared/ControlledPagination';
import { ReferralRequestStatus } from '../../../enums/ReferralRequestStatus';

interface ReferralDashboardListInterface {
    data: ReferralRequestListItemModel[] | undefined;
    openDetailPageHandler: any;
    currentTabIndex: number;
}

const PaginationContainer = styled.div`
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
`;

const ReferralDashboardList = (props: ReferralDashboardListInterface) => {
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(7);
    console.log(props.data);
    console.log(window.innerHeight);

    interface ReferralApplicationCellProps {
        // TODO
        // referral: ReferralRequestListItemModel;
        referral: ReferralRequestListItemModel;
        openDetailPageHandler: any;
    }

    const ReferralApplicationCell = ({ referral, openDetailPageHandler }: ReferralApplicationCellProps) => {
        const isDraft = referral.status === ReferralRequestStatus.Unfinished;
        const buttonText = isDraft ? 'Continue' : 'Check Status';

        return (
            <ReferralApplicationCellRow>
                <ReferralApplicationCellCol md={8}>
                    <ReferralApplicationCellCompanyImg>
                        <CompanyLogo companyLogoFilename={referral?.company_logo_filename} />
                    </ReferralApplicationCellCompanyImg>
                    <ReferralApplicationCellCompanyInfo>
                        <ReferralApplicationPrimaryTitle>
                            {referral?.referral_post_title}
                        </ReferralApplicationPrimaryTitle>
                        <ReferralApplicationSecondaryTitle>{referral?.company_title}</ReferralApplicationSecondaryTitle>
                    </ReferralApplicationCellCompanyInfo>
                </ReferralApplicationCellCol>
                <ReferralApplicationCellCol>
                    <ReferralStatus>
                        <ReferralApplicationStatusDot className={referral?.status} /> {referral?.status}
                    </ReferralStatus>
                    <Button
                        className="ml-auto"
                        color="primary"
                        variant="outlined"
                        onClick={() => openDetailPageHandler(referral)}
                        disabled={isDraft}
                    >
                        {buttonText}
                    </Button>
                </ReferralApplicationCellCol>
            </ReferralApplicationCellRow>
        );
    };

    const getPageData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return props.data?.slice(startIndex, endIndex);
    };

    useEffect(() => {
        if (props.data) {
            setPagesCount(Math.ceil(props.data.length / itemsPerPage));
        }
    }, [props.data, itemsPerPage]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handlePageOutOfRange = (page: number) => {
        // do nothing for now
    };
    return (
        <ReferralDashboardListCellBox display="flex" flexDirection="column">
            {getPageData().map((ref: ReferralRequestListItemModel, i) => (
                <ReferralApplicationCell key={i} referral={ref} openDetailPageHandler={props.openDetailPageHandler} />
            ))}
            <PaginationContainer>
                <ControlledPagination
                    currentPage={currentPage}
                    pagesCount={pagesCount}
                    pagingCallback={handlePageChange}
                    handlePageOutOfRange={handlePageOutOfRange}
                />
            </PaginationContainer>
            <ActionButtonRow>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        history.push('/referral/list');
                    }}
                >
                    Explore Referral Opportunities
                </Button>
            </ActionButtonRow>
        </ReferralDashboardListCellBox>
    );
};

export default ReferralDashboardList;

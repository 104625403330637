import { Auth, API } from 'aws-amplify';
import { JobFilter } from '../../interfaces/JobFilter';
import { SortOrder } from '../../enums/SortOrder';
import { jsonToJobs, jsonToCompany, jsonToUser, jsonToExperience, toBase64 } from './helpers';
import { Attachment, ReferralRequestInfoModel } from '../../interfaces/referral/ReferralRequestInfoModel';
import { ReferralRequestStatus } from '../../enums/ReferralRequestStatus';
import { ReferralPostStatus } from '../../enums/ReferralPostStatus';
import { ReferralPostsListItemModel } from '../../interfaces/referral/ReferralPostsListItemModel';
import { ReferralPostDetailModel } from '../../interfaces/referral/ReferralPostDetailModel';
import { ReferralPostListType } from '../../enums/ReferralPostListType';
import { ReferralPostType } from '../../enums/ReferralPostType';
import { ReferralRequestListItemModel } from '../../interfaces/referral/ReferralRequestListItemModel';
import { ReferralRequestDetailModel } from '../../interfaces/referral/ReferralRequestDetailModel';
import { ReferralRequestsListType } from '../../enums/ReferralRequestsListType';
import { ReferrerDetailModel } from '../../interfaces/referral/ReferrerDetailModel';
import { ReferrerInfoModel } from '../../interfaces/referral/ReferrerInfoModel';

const getPayload = (data: any) => {
    return {
        body: {
            data: data,
        },
    };
};

const refreshAuthToken = async () => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const newSession = await Auth.userSession(currentUser);
        const newIdToken = newSession.getIdToken().getJwtToken();
        return newIdToken;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

const getAuthToken = async (payload: any) => {
    const session = await Auth.currentSession();
    const expirationTime = session.getIdToken().getExpiration();
    const currentTime = Math.floor(Date.now() / 1000);
    let idToken: string;

    if (expirationTime > currentTime) {
        idToken = session.getIdToken().getJwtToken();
    } else {
        idToken = await refreshAuthToken();
    }

    payload['headers'] = {
        Authorization: `Bearer ${idToken}`,
    };
    return payload;
};

export const newGradJobsGet = async () => {
    return new Promise((resolve, reject) => {
        API.get('api', 'jobs/new-grad', {})
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                console.log(error.request);
                reject(error);
            });
    });
};
export const companyGet = async (id: number) => {
    return new Promise((resolve, reject) => {
        API.get('api', `companies/${id}`, {})
            .then((result) => {
                resolve(jsonToCompany(result));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const companiesGet = async (props = null) => {
    if (props) {
        const { title, industry, searchTerm, eVerified } = props;
        const data = {
            queryStringParameters: {
                ttl: title,
                ind: industry,
                trm: searchTerm,
                cmp_only: '1',
            },
        };
        if (eVerified !== null) {
            data.queryStringParameters['evf'] = eVerified ? '1' : '0';
        }
        return API.get('api', 'companies', data);
    }
    return API.get('api', 'companies', { queryStringParameters: { evf: '1' } });
};

export const hiringCompaniesGet = async () => {
    return API.get('api', 'companies', { queryStringParameters: { cmp_only: '1', hir: '1' } });
};

export const industriesGet = async (limit?: string) => {
    const payload = {
        queryStringParameters: {
            ind_only: '1',
        },
    };
    if (limit) {
        payload.queryStringParameters['ind_limit'] = limit;
    }

    return API.get('api', 'companies', payload);
};

export const filterOptionsGet = async () => {
    return API.get('api', 'filters', {});
};

export const sendEmailPOST = async (user_name: string, user_email: string, email_content: string) => {
    const data = {
        user_name,
        user_email,
        email_content,
    };

    return API.post('api', 'email', getPayload(data));
};

export const usersPOST = async (email: string) => {
    const data = {
        emailAddr: email,
    };

    return API.post('api', 'users', getPayload(data));
};

export const userGet = async (userId: number | string) => {
    const payload = await getAuthToken({});
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}`, payload)
            .then((result) => {
                resolve(jsonToUser(result));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const userGetResumes = async (userId: number | string) => {
    const payload = await getAuthToken({});
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/resumes`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// The same endpoint unsaves if the job is already saved for the user
export const saveJobPost = async (jobId: number, userId: number) => {
    const payload = await getAuthToken(getPayload({ job_id: jobId }));
    return new Promise((resolve, reject) => {
        API.post('api', `users/${userId}/jobs`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const jobGET = async (jobId: number) => {
    return new Promise((resolve, reject) => {
        API.get('api', `jobs/${jobId}`, {})
            .then((result) => {
                resolve(jsonToJobs([result])[0]);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const jobsGetByIds = async (ids: Array<number>) => {
    const payload = {
        queryStringParameters: {
            ids: ids,
        },
    };

    return new Promise((resolve, reject) => {
        API.get('api', 'jobs', payload)
            .then(({ results, total_count }) => {
                resolve({ results: jsonToJobs(results), totalCount: total_count });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const jobsGET = async (filterInstance?: JobFilter, page?: number) => {
    const payload = {
        queryStringParameters: {},
    };

    if (page !== null && page !== undefined) {
        payload.queryStringParameters['pg'] = page;
    }

    if (filterInstance.everified === false && filterInstance.h1b === false) {
        payload.queryStringParameters['evf'] = 1;
        payload.queryStringParameters['h1b'] = 1;
    } else if (filterInstance.everified === true && filterInstance.h1b === true) {
        payload.queryStringParameters['evf'] = 1;
        payload.queryStringParameters['h1b'] = 1;
    } else if (filterInstance.everified === false && filterInstance.h1b === true) {
        payload.queryStringParameters['h1b'] = 1;
    } else if (filterInstance.everified === true && filterInstance.h1b === false) {
        payload.queryStringParameters['evf'] = 1;
    }

    if (filterInstance && filterInstance.jobTitles && filterInstance.jobTitles.length > 0) {
        payload.queryStringParameters['ttl'] = filterInstance?.jobTitles;
    }
    if (filterInstance && filterInstance.companies && filterInstance.companies.length > 0) {
        payload.queryStringParameters['cmp'] = filterInstance?.companies;
    }
    if (filterInstance && filterInstance.locations && filterInstance.locations.length > 0) {
        payload.queryStringParameters['loc'] = filterInstance?.locations;
    }
    if (filterInstance && filterInstance.jobTypes && filterInstance.jobTypes.length > 0) {
        payload.queryStringParameters['typ'] = filterInstance?.jobTypes;
    }
    if (filterInstance && filterInstance.industries && filterInstance.industries.length > 0) {
        payload.queryStringParameters['ind'] = filterInstance?.industries;
    }
    if (filterInstance && filterInstance.levels && filterInstance.levels.length > 0) {
        if (filterInstance.optionsCache && filterInstance.optionsCache['levels']) {
            payload.queryStringParameters['exl'] = filterInstance?.levels.map(
                (key) => filterInstance.optionsCache['levels'][key],
            );
        } else {
            // When the optionCache is not present, use the filterOptionGet API to get the levels option to levels option index mapping
            const levelOptionToIndexMap = {};
            await filterOptionsGet().then((response) => {
                levelOptionToIndexMap['levels'] = response['exp_levels'].reduce((map, obj) => {
                    map[obj['term']] = obj['id'];
                    return map;
                }, {});
            });
            payload.queryStringParameters['exl'] = filterInstance?.levels.map(
                (key) => levelOptionToIndexMap['levels'][key],
            );
        }
    }

    if (filterInstance && filterInstance.sortBy) {
        switch (filterInstance.sortBy) {
            case SortOrder.Relevance:
            case SortOrder.PostDate:
                payload.queryStringParameters['sort'] = filterInstance.sortBy;
                break;
            default:
                payload.queryStringParameters['sort'] = SortOrder.PostDate;
        }
    } else {
        payload.queryStringParameters['sort'] = SortOrder.PostDate;
    }

    return new Promise((resolve, reject) => {
        API.get('api', 'jobs', payload)
            .then(({ results, total_count }) => {
                resolve({ results: jsonToJobs(results), totalCount: total_count });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const followCompanyPost = async (companyId: number, userId: number) => {
    const payload = await getAuthToken(getPayload({ company_id: companyId }));
    return new Promise((resolve, reject) => {
        API.post('api', `users/${userId}/company-followings`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const followCompanyGet = async (userId: number) => {
    const payload = await getAuthToken({});
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/company-followings`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const followCompanyDelete = async (companyId: number, userId: number) => {
    const payload = await getAuthToken(getPayload({ company_id: companyId }));
    return new Promise((resolve, reject) => {
        API.del('api', `users/${userId}/company-followings`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const companyGET = async (companyId: number) => {
    return new Promise((resolve, reject) => {
        API.get('api', `companies/${companyId}`, {})
            .then((result) => {
                resolve(jsonToCompany(result));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const companyGetByIds = async (ids: Array<number>) => {
    const payload = {
        queryStringParameters: {
            ids: ids,
        },
    };
    return new Promise((resolve, reject) => {
        API.get('api', 'companies', payload)
            .then((results) => {
                const companies = results['companies'].map((object) => {
                    return jsonToCompany(object);
                });
                resolve(companies);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const experiencesGet = async (userId: number) => {
    const payload = await getAuthToken(getPayload({}));
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/experiences`, payload)
            .then((result) => {
                const experiences = result.map((obj) => {
                    return jsonToExperience(obj);
                });
                resolve(experiences);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const experiencePost = async (
    userId: number,
    experience_type,
    description,
    start_time,
    end_time,
    organization_name,
    title,
    location,
    experience_id?,
) => {
    let payload;
    if (experience_id) {
        payload = await getAuthToken(
            getPayload({
                experience_id: experience_id,
                experience_type: experience_type,
                description: description,
                start_time: start_time,
                end_time: end_time,
                organization_name: organization_name,
                title: title,
                location: location,
            }),
        );
    } else {
        payload = await getAuthToken(
            getPayload({
                experience_type: experience_type,
                description: description,
                start_time: start_time,
                end_time: end_time,
                organization_name: organization_name,
                title: title,
                location: location,
            }),
        );
    }
    return new Promise((resolve, reject) => {
        API.post('api', `users/${userId}/experiences`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const experienceDelete = async (userId: number, experience_id) => {
    const payload = await getAuthToken(getPayload({ experience_id: experience_id }));
    return new Promise((resolve, reject) => {
        API.del('api', `users/${userId}/experiences`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferralRequestWrite = async (userId: number, referralRequestData: ReferralRequestInfoModel) => {
    const newReferralRequestData = Object.assign({}, referralRequestData);
    const convert = async (fileContent) => {
        const convertedContent = await toBase64(fileContent);
        if (convertedContent) {
            return {
                attachment_data: convertedContent.split(',')[1],
                file_name: fileContent.name,
            } as Attachment;
        }
        console.error(`failed to convert file ${fileContent.name}`);
        return null;
    };

    if (referralRequestData.attachments.length > 0) {
        newReferralRequestData.attachments = await Promise.all(referralRequestData.attachments.map(await convert));
    }
    const payload = await getAuthToken(getPayload(newReferralRequestData));
    return new Promise((resolve, reject) => {
        API.post('api', `users/${userId}/referral-requests`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferralPostsReadGet = async (
    userId: number,
    postType: ReferralPostType = null,
    listType: ReferralPostListType = ReferralPostListType.Index,
) => {
    const convertToReferralPostsListItemModel = (object: object) => {
        return {
            id: object['id'],
            referrer_id: object['referrer_id'],
            title: object['title'],
            type: object['type'],
            job_location: object['job_location'],
            created_at: object['created_at'],
            updated_at: object['updated_at'],
            deleted_at: object['deleted_at'],
            status: object['status'],
            company_name: object['company_title'],
            company_logo: object['company_logo_filename'],
        } as ReferralPostsListItemModel;
    };

    let payload = {
        queryStringParameters: {},
    };

    if (listType) {
        payload.queryStringParameters['list_type'] = listType;
    }

    if (postType) {
        payload.queryStringParameters['type'] = postType;
    }

    payload = await getAuthToken(payload);

    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/referral-posts`, payload)
            .then((result) => {
                const returnObj: ReferralPostsListItemModel[] = result.map((x) =>
                    convertToReferralPostsListItemModel(x),
                );
                resolve(returnObj);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const referralPost = async (
    userId: number,
    status: ReferralPostStatus,
    referrer_id: number,
    title,
    type,
    job_link,
    job_location,
    requirements,
    exp_level,
    job_type,
) => {
    const payload = await getAuthToken(
        getPayload({
            referrer_id: referrer_id,
            status: status,
            title: title,
            type: type,
            job_link: job_link,
            job_location: job_location,
            requirements: requirements,
            exp_level: exp_level,
            job_type: job_type,
        }),
    );
    return new Promise((resolve, reject) => {
        API.post('api', `users/${userId}/referral-posts`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferralPostReadGet = async (userId: number, postId: number) => {
    const convertToReferralPostDetailModel = (object: object) => {
        return {
            company_title: object['company_title'],
            company_id: object['company_id'],
            company_logo_filename: object['company_logo_filename'],
            id: object['id'],
            referrer_id: object['referrer_id'],
            title: object['title'],
            type: object['type'],
            job_link: object['job_link'],
            job_location: object['job_location'],
            requirements: JSON.parse(object['requirements']),
            exp_level: object['exp_level'],
            job_type: object['job_type'],
            created_at: object['created_at'],
            updated_at: object['updated_at'],
            deleted_at: object['deleted_at'],
            exp_level_id: Number(object['deleted_at']),
            job_type_id: Number(object['deleted_at']),
            status: object['status'],
        } as ReferralPostDetailModel;
    };

    let payload = {
        queryStringParameters: {},
    };

    payload = await getAuthToken(payload);
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/referral-posts/${postId}`, payload)
            .then((result) => {
                resolve(convertToReferralPostDetailModel(result));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferralRequestsRead = async (
    userId: number,
    status: ReferralRequestStatus = null,
    listType: ReferralRequestsListType,
    referralPostId: number,
) => {
    const convert = (object: object) => {
        let jobLinks: string[] = [];
        try {
            jobLinks = JSON.parse(object['job_links']);
        } catch {
            jobLinks = [object['job_links']];
        }
        return {
            id: object['id'],
            referee_id: object['referee_id'],
            referral_post_id: object['referral_post_id'],
            first_name: object['first_name'],
            last_name: object['last_name'],
            email: object['email'],
            job_links: jobLinks,
            status: object['status'],
            created_at: object['created_at'],
            updated_at: object['updated_at'],
            deleted_at: object['deleted_at'],
            company_id: object['company_id'],
            company_logo_filename: object['company_logo_filename'],
            company_title: object['company_title'],
            posted_at: object['posted_at'],
            referral_post_title: object['referral_post_title'],
            self_intro: object['self_intro'],
        } as ReferralRequestListItemModel;
    };

    let payload = {
        queryStringParameters: {
            list_type: listType,
        },
    };

    if (status) {
        payload.queryStringParameters['status'] = status;
    }

    if (referralPostId) {
        payload.queryStringParameters['referral_post_id'] = referralPostId;
    }

    payload = await getAuthToken(payload);
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/referral-requests`, payload)
            .then((result) => {
                const returnObj: ReferralRequestListItemModel[] = result.map((x) => convert(x));
                resolve(returnObj);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferralRequestRead = async (userId: number, requestId: number) => {
    const convert = (object: object) => {
        let jobLinks: string[] = [];
        try {
            jobLinks = JSON.parse(object['job_links']);
        } catch {
            jobLinks = [object['job_links']];
        }
        return {
            id: object['id'],
            referee_id: object['referee_id'],
            referral_post_id: object['referral_post_id'],
            first_name: object['first_name'],
            last_name: object['last_name'],
            email: object['email'],
            job_links: jobLinks,
            status: object['status'],
            created_at: object['created_at'],
            updated_at: object['updated_at'],
            deleted_at: object['deleted_at'],
            company_id: object['company_id'],
            company_logo_filename: object['company_logo_filename'],
            company_title: object['company_title'],
            self_intro: object['self_intro'],
            attachments: object['attachments'],
        } as ReferralRequestDetailModel;
    };

    let payload = {
        queryStringParameters: {},
    };

    payload = await getAuthToken(payload);
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/referral-requests/${requestId}`, payload)
            .then((result) => {
                resolve(convert(result));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferralRequestEdit = async (userId: number, requestId: number, requestStatus: ReferralRequestStatus) => {
    const payload = await getAuthToken(
        getPayload({
            status: requestStatus,
        }),
    );

    return new Promise((resolve, reject) => {
        API.patch('api', `users/${userId}/referral-requests/${requestId}`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferralPostEdit = async (userId: number, referralPost: ReferralPostDetailModel) => {
    const payload = await getAuthToken(
        getPayload({
            title: referralPost.title,
            type: referralPost.type,
            job_link: referralPost.job_link,
            job_location: referralPost.job_location,
            requirements: JSON.stringify(referralPost.requirements),
            exp_level: referralPost.exp_level,
            job_type: referralPost.job_type,
            status: referralPost.status,
        }),
    );

    return new Promise((resolve, reject) => {
        API.patch('api', `users/${userId}/referral-posts/${referralPost.id}`, payload)
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ReferrerRead = async (userId: number, referrerId: number) => {
    const convert = (object: object) => {
        return {
            id: object['id'],
            description: object['description'],
            company_id: object['company_id'],
            created_at: object['created_at'],
            updated_at: object['updated_at'],
            deleted_at: object['deleted_at'],
            first_name: object['first_name'],
            last_name: object['last_name'],
            profile_image_filename: object['profile_image_filename'],
            user_id: object['user_id'],
        } as ReferrerInfoModel;
    };

    const payload = await getAuthToken({});
    return new Promise((resolve, reject) => {
        API.get('api', `users/${userId}/referrers/${referrerId}`, payload)
            .then((result) => {
                resolve(convert(result));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const NewestJobGet = async (count: number) => {
    const payload = {
        queryStringParameters: {
            new: count,
        },
    };

    return new Promise((resolve, reject) => {
        API.get('api', 'jobs', payload)
            .then(({ results }) => {
                resolve({ jobs: jsonToJobs(results) });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

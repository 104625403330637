import { Button, FormControl, FormControlLabel, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { ActionButton } from '../landing/sections/StyledSectionComponents';

export const UserAuthForm = styled.div`
    display: flex;
    flex-flow: column wrap;
    text-align: center;
`;

export const UserAuthFormControl = styled(FormControl)`
    .MuiButton-containedPrimary {
        height: 40px;
        margin-top: 20px;
        text-transform: none;
        font-family: 'Roboto';
        line-height: 20px;
        font-weight: 700;
        font-size: 16px;
        border-radius: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .MuiButton-outlined {
        border: 1px solid #d2d2d2;
        height: 40px;
        border-radius: 10px;
        border-radius: 10px;
        text-transform: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #122534;
    }

    .MuiFormHelperText-root.Mui-error {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #d83434;
    }

    .MuiFormHelperText-contained {
        margin-left: 0;
    }

    .MuiInputBase-input {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #122534;
    }

    // Remove the background color when auto fill happen with chrome
    .MuiOutlinedInput-input:-webkit-autofill {
        transition: background-color 1s 5000s;
    }
`;

export const UserAuthInputField = styled(TextField)`
    .MuiOutlinedInput-root {
        margin-top: 20px;
        padding: 10px 16px;
        border-radius: 10px;
        background: #ffffff;
        height: 40px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6b7280;
    }
    .MuiOutlinedInput-input {
        margin-left: 12px;
    }
`;

export const UserAuthInputFieldFirst = styled(UserAuthInputField)`
    .MuiOutlinedInput-root {
        margin-top: 0;
    }
`;

export const UserAuthIcon = styled.img``;

export const UserAuthButton = styled(Button)``;

export const UserAuthLinkButton = styled(ActionButton)`
    margin-top: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Primary/Primary Blue */

    color: #165ebf;
`;

export const UserAuthFormControlLabel = styled(FormControlLabel)`
    justify-content: center;
    margin: 40px 0 0 0;
    color: #122534;
    span,
    .MuiButton-root {
        text-transform: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
`;

export const UserAuthFormText = styled.div`
    text-align: left;
    margin-top: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    /* Text/Black */

    color: #122534;
    a {
        color: #165ebf;
    }
`;

export const UserAuthFormSubtitle = styled(UserAuthFormText)`
    margin-top: 0;
    font-weight: 500;
    font-size: 16px;
`;

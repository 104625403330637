import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

interface AuthDialogProps {
    open: any;
    close: any;
    page: string;
    children?: React.ReactChild;
}

const UserAuthPageLogo = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        width: 400px;
    }
    .MuiDialogContent-root {
        padding: 8px 40px 40px 40px;
    }
`;

const JoinUsTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */
    color: #000000;
    padding: 40px 40px 32px 40px;
`;

const AuthDialog = (props: AuthDialogProps) => {
    return (
        <StyledDialog className="dialog" open={props.open} onClose={props.close} aria-labelledby="auth-modal">
            {props.page === 'signIn' ? (
                <UserAuthPageLogo>
                    <img src="https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/userAuthPageLogo.svg" />
                </UserAuthPageLogo>
            ) : (
                <JoinUsTitle> Join AiTou</JoinUsTitle>
            )}

            <DialogContent>{props.children}</DialogContent>
        </StyledDialog>
    );
};

export default AuthDialog;

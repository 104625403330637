import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as AuthHelper from '../../auth/helpers/AWSHelpers';
import * as APIs from '../../apis/APIs';
import ReferralTracker from '../../referral/referral-dashboard/ReferralTracker';
import { ReferralRequestsListType } from '../../../enums/ReferralRequestsListType';
import { ReferralRequestListItemModel } from '../../../interfaces/referral/ReferralRequestListItemModel';
import { TabContentBox, TabContentStatusBox } from '../../shared/ReferralSharedComponents';
import { LoadingSpinner } from '../../shared/Loading';
import { CircularProgress } from '@material-ui/core';

export interface ReferralTabContentProps {
    userId: any;
    isShowApplicationPage: boolean;
    setIsShowApplicationPage: (val: boolean) => void;
}

export const ReferralTabContent = (props: ReferralTabContentProps) => {
    const [isLoading, setIsLoading] = useState(false);
    // Need to define it as undefined as empty array will be conflict with the real case where returned data is empty array
    const [referralRequests, setReferralRequests] = useState<ReferralRequestListItemModel[]>([undefined]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userCustomId, setUserCustomId] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [postOpen, setPostOpen] = useState<string | undefined>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [postData, setPostData] = useState<any | undefined>(null);

    const getOwnReferralRequests = async () => {
        setIsLoading(true);
        await APIs.ReferralRequestsRead(props.userId, null, ReferralRequestsListType.Owned, null).then((res: any[]) => {
            setReferralRequests(res);
            setIsLoading(false);
        });
    };

    // const getReferralPostDetail = async (postId: number) => {
    //     setIsLoading(true);
    //     await APIs.ReferralPostReadGet(props.userId, postId).then(async (res: any[]) => {
    //         setPostData(res);
    //         setIsLoading(false);
    //     });
    // };

    // const checkPostDetailHandler = (id: any, post: any) => {
    //     setPostOpen(id);
    //     getReferralPostDetail(id);
    // };

    useEffect(() => {
        let isCanceled = false;

        AuthHelper.currentUserInfo()
            .then(async (response) => {
                if (response && !isCanceled && response.attributes) {
                    setUserCustomId(response.attributes['custom:user_id']);
                    await getOwnReferralRequests();
                }
            })
            .finally(() => {
                if (!isCanceled) {
                    setIsLoading(false);
                }
            });
        return () => {
            isCanceled = true;
        };
    }, []);

    return (
        <TabContentBox
            display="flex"
            flexDirection="column"
            className={props.isShowApplicationPage ? 'bg-main' : 'bg-white'}
        >
            {isLoading && (
                <LoadingSpinner>
                    <CircularProgress />
                </LoadingSpinner>
            )}
            {!isLoading && referralRequests && referralRequests?.length > 0 && (
                <ReferralTracker
                    userId={props.userId}
                    data={referralRequests}
                    isShowApplicationPage={props.isShowApplicationPage}
                    setIsShowApplicationPage={props.setIsShowApplicationPage}
                />
            )}
            {!isLoading && referralRequests?.length === 0 && (
                <Container style={{ margin: 'auto auto auto auto' }}>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <img src="https://s3.amazonaws.com/aitou.io.bucket/icons/fileIcon.svg" />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <TabContentStatusBox>No referral requests yet</TabContentStatusBox>
                        </Col>
                    </Row>
                </Container>
            )}
        </TabContentBox>
    );
};

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    CircularProgress,
    Container,
    createTheme,
    IconButton,
    InputLabel,
    Modal,
    Paper,
    ThemeProvider,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import BootstrapInput from '../../shared/BootstrapInput';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const EditProfileTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    margin-bottom: 20px;
    color: #000000;
`;
const EditProfileInput = styled(BootstrapInput)``;

const DropzoneAreaContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px;
`;

const ModalPaper = styled(Paper)`
    padding: 30px;
`;
const ModalHeader = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const CloseIconButton = styled(IconButton)`
    padding: 0 !important;
    border-radius: 0 !important;
`;

const CloseIconStyle = styled(CloseIcon)`
    color: #858995;
`;

const ModalInput = styled(Row)`
    margin-bottom: 20px;
`;

const ModalUpload = styled(Row)`
    margin-bottom: 40px;
`;

const ModalAction = styled(Row)`
    display: flex;
    flex-flow: row;
    justify-content: end;
    padding-right: 16px;
`;

const ActionButton = styled(Button)`
    background: #165ebf !important;
    border-radius: 10px !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;

    color: #ffffff !important;
    text-transform: none !important;
    padding: 8px 16px !important;
`;

const ModalActionButtons = styled.div``;
export interface ProfileEditModalProps {
    isLoading: boolean;
    isOpen: boolean;
    setIsModalOpen: any;
    userFirstName: string;
    userLastName: string;
    onSubmitHandler: any;
}

export const ProfileEditModal = ({
    isLoading,
    isOpen,
    setIsModalOpen,
    userFirstName,
    userLastName,
    onSubmitHandler,
}: ProfileEditModalProps) => {
    const [firstName, setFirstName] = useState(userFirstName);
    const [lastName, setLastName] = useState(userLastName);
    const [profileImageContent, setProfileImageContent] = useState(undefined);
    const [profileImageFilename, setProfileImageFilename] = useState(undefined);

    const cancelHandler = () => {
        setFirstName(userFirstName);
        setLastName(userLastName);
        setProfileImageContent(undefined);
        setProfileImageFilename(undefined);

        setIsModalOpen(false);
    };

    const onSubmit = async () => {
        onSubmitHandler({
            profileImageFilename,
            profileImageContent,
            firstName,
            lastName,
        });
    };

    const theme = createTheme({
        overrides: {
            // Since Dropzone is a WIP feature from MUI, we use experimental theme styling here.
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            MuiDropzoneArea: {
                root: {
                    borderRadius: '10px',
                    border: '1px dashed black',
                    display: 'flex',
                    flexFlow: 'column wrap',
                    justifyContent: 'center',
                    minHeight: '150px',
                },
                text: {
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    marginBottom: '16px',
                },
                icon: {
                    color: '#165EBF',
                },
            },
        },
    });

    return (
        <Modal
            open={isOpen}
            onClose={cancelHandler}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Container maxWidth="md">
                <ModalPaper>
                    <ModalHeader>
                        <EditProfileTitle>Edit Profile</EditProfileTitle>
                        <CloseIconButton onClick={cancelHandler} disabled={isLoading}>
                            <CloseIconStyle />
                        </CloseIconButton>
                    </ModalHeader>
                    <ModalInput>
                        <Col sm={6} md={6}>
                            <EditProfileInput
                                id="profile-first-name-input"
                                label="First Name"
                                value={firstName}
                                disabled={isLoading}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Col>
                        <Col sm={6} md={6}>
                            <EditProfileInput
                                id="profile-last-name-input"
                                label="Last Name"
                                value={lastName}
                                disabled={isLoading}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Col>
                    </ModalInput>
                    <ModalUpload>
                        <Col sm={6} md={6}>
                            <InputLabel
                                htmlFor="profile-image-upload"
                                style={{
                                    transform: 'translate(0, 1.5px) scale(1)',
                                    transformOrigin: 'top left',
                                }}
                            >
                                Profile Image
                            </InputLabel>
                            <ThemeProvider theme={theme}>
                                {isLoading ? (
                                    <SpinnerWrapper>
                                        <CircularProgress />
                                    </SpinnerWrapper>
                                ) : (
                                    <DropzoneAreaContainer>
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            dropzoneText={'Select or drop an image here'}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setProfileImageFilename(files[0].name);
                                                    setProfileImageContent(files[0]);
                                                } else {
                                                    setProfileImageFilename(undefined);
                                                    setProfileImageContent(undefined);
                                                }
                                            }}
                                        />
                                    </DropzoneAreaContainer>
                                )}
                            </ThemeProvider>
                        </Col>
                    </ModalUpload>
                    <ModalAction>
                        <ModalActionButtons>
                            <ActionButton
                                id="profile-edit-save-btn"
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                                disabled={isLoading}
                            >
                                Save
                            </ActionButton>
                        </ModalActionButtons>
                    </ModalAction>
                </ModalPaper>
            </Container>
        </Modal>
    );
};

import React from 'react';
import ReactGA from 'react-ga';
import SponsorCheckerContainer from '../../components/landing/sections/SponsorCheckerContainer';
import SponsorDataContainer from '../../components/landing/sections/SponsorDataContainer';
import ReferralProgramContainer from '../../components/landing/sections/ReferralProgramContainer';
import IntroContainer from '../../components/landing/sections/IntroContainer';

interface LandingProps {
    navComponent: React.ReactNode;
}

const Landing = (props: LandingProps) => {
    ReactGA.initialize('UA-180746720-1');
    ReactGA.pageview('/home');

    return (
        <>
            <div>
                {props.navComponent}
                <main>
                    {/* TODO: Once we have more referrer signed up, we will display this section */}
                    <IntroContainer />
                    <SponsorCheckerContainer />
                    <SponsorDataContainer />
                    {/*<ReferralProgramContainer />*/}
                </main>
            </div>
        </>
    );
};

export default Landing;

import React, { useState } from 'react';
import * as AuthHelper from '../helpers/AWSHelpers';
import Button from '@material-ui/core/Button';
import * as RestApi from '../../apis/APIs';
import { InputAdornment } from '@material-ui/core';
import {
    UserAuthButton,
    UserAuthForm,
    UserAuthFormControl,
    UserAuthFormControlLabel,
    UserAuthFormText,
    UserAuthIcon,
    UserAuthInputField,
    UserAuthInputFieldFirst,
} from '../../shared/UserAuthForm';

interface UserSignUpProps {
    done: any;
    setUsernameHandler: any;
    setUserIdHandler: any;
    signInHandler: any;
    confirmHandler: any;
    returnToSignInHandler: any;
}

type ValidationErrorTypes = {
    type: 'email' | 'psw' | 'confirmpsw' | 'none';
    msg: string;
};

export default function UserSignUp(props: UserSignUpProps) {
    // internal state
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validationErrorType, setValidationErrorType] = useState<ValidationErrorTypes>({ type: 'none', msg: '' });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeEmail = (val: any) => {
        setEmail(val.target.value);
        setError('');
        props.setUsernameHandler(val.target.value);
    };
    const handleChangePassword = (val: any) => {
        setPassword(val.target.value);
        setError('');
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChangeConfirmPassword = (val: any) => {
        setConfirmPassword(val.target.value);
        setError('');
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!validateEmailPwd()) {
            return;
        }
        setIsLoading(true);
        RestApi.usersPOST(email)
            .then((data) => {
                if (data && data.body && data.body.user_id) {
                    props.setUserIdHandler(data.body.user_id);
                    AuthHelper.signUp(password, email, data.body.user_id)
                        .then((response) => {
                            props.confirmHandler(email, password);
                        })
                        .catch((error) => {
                            if (error.code === 'UsernameExistsException') {
                                setError(error.message + ', please sign in');
                            } else {
                                console.error(error);
                                setError(error.message);
                            }
                        });
                } else {
                    setError('Internal Error, please try again');
                }
            })
            .catch((error) => {
                console.error(error);
                setError('Internal Error, please try again');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const validateEmailPwd = () => {
        if (!email) {
            setError('Email is missing');
            setValidationErrorType({ type: 'email', msg: 'Email is missing' });
        } else if (!password) {
            setError('Password is missing');
            setValidationErrorType({ type: 'psw', msg: 'Password is missing' });
        } else if (password && password.length < 8) {
            setError('Minimum password length is 8');
            setValidationErrorType({ type: 'psw', msg: 'Minimum password length is 8' });
        } else if (password !== confirmPassword) {
            setError(`The password doesn't match`);
            setValidationErrorType({ type: 'confirmpsw', msg: `The password doesn't match` });
        } else {
            setValidationErrorType({ type: 'none', msg: '' });
            return true;
        }
        return false;
    };

    return (
        <UserAuthForm>
            <UserAuthFormControl>
                <UserAuthInputFieldFirst
                    autoFocus
                    required
                    fullWidth
                    id="email-adrress"
                    placeholder="Please enter your email"
                    type="email"
                    error={!!error && validationErrorType.type === 'email'}
                    helperText={validationErrorType.type === 'email' && validationErrorType.msg}
                    variant="outlined"
                    onChange={handleChangeEmail}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/EmailIcon.svg" />
                            </InputAdornment>
                        ),
                    }}
                />
                <UserAuthInputField
                    required
                    fullWidth
                    id="password"
                    placeholder="Please enter your password"
                    type={showPassword ? 'text' : 'password'}
                    error={!!error && validationErrorType.type === 'psw'}
                    helperText={
                        validationErrorType.type === 'psw' && validationErrorType.msg
                        /* as the error will display below confirm input */
                    }
                    variant="outlined"
                    onChange={handleChangePassword}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/passwordIcon.svg" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {showPassword ? (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/hidepasswordicon.svg"
                                        onClick={handleClickShowPassword}
                                    />
                                ) : (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/showpasswordicon.svg"
                                        onClick={handleClickShowPassword}
                                    />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <UserAuthInputField
                    required
                    fullWidth
                    id="confirm-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Please confirm your password"
                    error={!!error && validationErrorType.type === 'confirmpsw'}
                    helperText={validationErrorType.type === 'confirmpsw' && validationErrorType.msg}
                    variant="outlined"
                    onChange={handleChangeConfirmPassword}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/passwordIcon.svg" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {showConfirmPassword ? (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/hidepasswordicon.svg"
                                        onClick={handleClickShowConfirmPassword}
                                    />
                                ) : (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/showpasswordicon.svg"
                                        onClick={handleClickShowConfirmPassword}
                                    />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <UserAuthFormText>
                    By clicking Agree & Join, you agree to the AiTou <a href="">User Agreement</a>,
                    <a href=""> Privacy</a>, and
                    <a href=""> Cookie Policy</a>.
                </UserAuthFormText>
                <UserAuthButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    {`Agree & Join`}
                </UserAuthButton>
                <UserAuthFormControlLabel
                    control={
                        <Button
                            className="no-transform"
                            color="primary"
                            disabled={isLoading}
                            onClick={props.returnToSignInHandler}
                        >
                            Sign In
                        </Button>
                    }
                    label="Already have an account?"
                    labelPlacement={'start'}
                />
            </UserAuthFormControl>
        </UserAuthForm>
    );
}

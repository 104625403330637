import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import DehazeIcon from '@mui/icons-material/Dehaze';
import './Nav.scss';
import { Divider, useScrollTrigger, useTheme, useMediaQuery } from '@material-ui/core';
import { AuthContext, HandlerContext } from '../../App';
import { NavMenu, NavItem } from '@mui-treasury/components/menu/navigation';
import { useFloatNavigationMenuStyles } from '@mui-treasury/styles/navigationMenu/float';
import HomeIcon from '@mui/icons-material/Home';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { IconButton, List, ListItem, Drawer, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { signOut } from '../../../components/auth/helpers/AWSHelpers';
import * as AuthHelper from '../../../components/auth/helpers/AWSHelpers';
/**
@desc this class will hold functions for navigation bar
@author Bin Xu
*/

interface NavProps {
    userAuthComponent: any;
}

const NavSection = styled(Container)`
    padding: 8px;
    .FloatNavigationMenu-menu-1:not:last {
        margin-top: 14px;
        margin-bottom: 14px;
        height: 100%;
    }

    .row {
        align-items: center;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .FloatNavigationMenu-item-11 {
        padding: 20px 16px;
    }
    &.scroll {
        background: #ffffff;
        padding: 0rem 0rem;
    }
    @include mq('xs') {
        padding: 0rem;
    }
`;

const NavRow = styled.div`
    background-color: #ffffff;
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @media (max-width: 960px) {
        padding: 0 16px;
    }
    .FloatNavigationMenu-item-5:not(:first-child) {
        margin-left: 0px;
    }

    .FloatNavigationMenu-item-11:not(:first-child) {
        margin-left: 0;
    }

    .FloatNavigationMenu-item-5 {
        color: #6b7280;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const NavLogoWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
`;

const NavLogo = styled(NavLink)``;

const NavDividerVert = styled(Divider)`
    margin-left: 16px !important;
    margin-right: 16px !important;
    background: #d1d5db;
    height: 28px !important;
`;

const NavDividerHoriz = styled(Divider)`
    margin-top: 24px !important;
    margin-bottom: 24px !important;
    background: #d1d5db;
`;

const NavDrawer = styled(Drawer)`
    && {
        .MuiDrawer-paper {
            padding: 24px;
            width: 264px;
        }
    }
`;

const ProfileListItem = styled(ListItem)`
    && {
        padding: 0 0 0 0;
        &:hover {
            text-decoration: none;
        }
    }
`;

const NavListItem = styled(ListItem)`
    && {
        padding: 0;
        &:hover {
            text-decoration: none;
        }
    }
`;

const NavListItemButton = styled(ListItemButton)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '0 !important',
}));

const NavListItemIcon = styled(ListItemIcon)`
    && {
        min-width: unset;
    }
`;

const NavListItemText = styled(ListItemText)`
    && {
        color: #6b7280;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const NavList = styled(List)`
    && {
        padding: 0;
    }
    display: flex;
    flex-flow: column wrap;
    padding: 0;
`;

export default function Nav(props: NavProps) {
    const theme = useTheme();
    const useMobileDisplay = useMediaQuery(theme.breakpoints.down('sm'));
    const { authStateUpdateHandler, userContextUpdateHandler } = useContext(HandlerContext);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        let mounted = true;
        setIsSignedIn(false);
        AuthHelper.currentUserInfo()
            .then((res) => {
                if (res && res.username && mounted) {
                    setIsSignedIn(true);
                }
            })
            .catch((err) => {
                if (mounted) {
                    console.error(err.message);
                }
            });
        return () => {
            mounted = false;
        };
    }, []);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleSignOutAttempt = () => {
        signOut()
            .then((res) => {
                authStateUpdateHandler(null, null);
                userContextUpdateHandler(null, null, null);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <AuthContext.Consumer>
            {({ userId, isLoggedIn, isReferrer }) => (
                <NavSection fluid={useMobileDisplay}>
                    <NavRow>
                        <NavLogoWrapper>
                            <NavLogo to={'/'}>
                                <img
                                    src={'https://s3.amazonaws.com/aitou.io.bucket/images/aitou_logo.svg'}
                                    alt="爱投"
                                />
                            </NavLogo>
                        </NavLogoWrapper>
                        {useMobileDisplay ? (
                            <IconButton onClick={toggleDrawer}>
                                <DehazeIcon color="primary" />
                            </IconButton>
                        ) : (
                            <NavMenu
                                gutter={1}
                                style={{ alignItems: 'center' }}
                                useStyles={useFloatNavigationMenuStyles}
                            >
                                {/*<NavItem href="https://chrome.google.com/webstore/detail/aitou-visa-sponsor-checke/kkdfahnaohddaenhpefgbmkgkpnakamo">*/}
                                {/*    Sponsor Checker*/}
                                {/*</NavItem>*/}
                                <NavItem as={NavLink} to={'/about'}>
                                    About
                                </NavItem>
                                <NavDividerVert orientation="vertical" />
                                {props.userAuthComponent}
                            </NavMenu>
                        )}
                    </NavRow>
                    {useMobileDisplay && (
                        <NavDrawer anchor="right" open={open} onClose={toggleDrawer}>
                            <NavList>
                                <ProfileListItem>{props.userAuthComponent}</ProfileListItem>
                                <NavDividerHoriz />
                                <NavListItem
                                    style={{ paddingBottom: 48 }}
                                    component={NavLink}
                                    to="/"
                                    onClick={toggleDrawer}
                                >
                                    <NavListItemButton>
                                        <NavListItemIcon>
                                            <HomeIcon />
                                        </NavListItemIcon>
                                        <NavListItemText primary="Home" />
                                    </NavListItemButton>
                                </NavListItem>
                                {/*<NavListItem*/}
                                {/*    component="a"*/}
                                {/*    href="https://chrome.google.com/webstore/detail/aitou-visa-sponsor-checke/kkdfahnaohddaenhpefgbmkgkpnakamo"*/}
                                {/*    target="_blank"*/}
                                {/*    onClick={toggleDrawer}*/}
                                {/*>*/}
                                {/*    <NavListItemButton>*/}
                                {/*        <NavListItemIcon>*/}
                                {/*            <FactCheckIcon />*/}
                                {/*        </NavListItemIcon>*/}
                                {/*        <NavListItemText primary="Sponsor Checker" />*/}
                                {/*    </NavListItemButton>*/}
                                {/*</NavListItem>*/}
                                <NavListItem component={NavLink} to="/about" onClick={toggleDrawer}>
                                    <NavListItemButton>
                                        <NavListItemIcon>
                                            <EmojiPeopleIcon />
                                        </NavListItemIcon>
                                        <NavListItemText primary="About" />
                                    </NavListItemButton>
                                </NavListItem>
                                {isSignedIn && (
                                    <>
                                        <NavDividerHoriz />
                                        <NavListItem onClick={handleSignOutAttempt}>
                                            <NavListItemButton>
                                                <NavListItemIcon>
                                                    <ExitToAppIcon />
                                                </NavListItemIcon>
                                                <NavListItemText primary="Log Out" />
                                            </NavListItemButton>
                                        </NavListItem>
                                    </>
                                )}
                            </NavList>
                        </NavDrawer>
                    )}
                </NavSection>
            )}
        </AuthContext.Consumer>
    );
}

import React from 'react';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
import { Typography } from '@mui/material';
import styled from 'styled-components';

const IntroSectionContainer = styled(Container)`
    padding-top: 90px;
    padding-bottom: 90px;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

const IntroSection = styled(Row)`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0;
`;

const IntroTextWrapper = styled(Col)`
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin-bottom: 48px;
`;

const IntroTitle = styled.div`
    margin-bottom: 20px;
    font-size: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* identical to box height, or 92% */

    display: flex;
    align-items: center;

    /* Primary/Primary Blue */

    color: #165ebf;

    @media (max-width: 960px) {
        font-size: 32px;
        font-weight: 600;
    }
`;

const IntroText = styled.div`
    color: #858995;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 48px;
`;

const IntroButtonGroup = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
`;

const SponsorCheckerBtn = styled(Button)`
    && {
        padding: 8px 22px;
        text-transform: none;
    }
`;

const FindReferralBtn = styled(Button)`
    && {
        padding: 8px 22px;
    }
`;

const SponsorCheckerText = styled(Typography)`
    && {
        font-size: 15px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        margin-right: 8px;
        text-transform: uppercase;
        color: white;
    }
`;

const FindReferralText = styled(Typography)`
    && {
        font-size: 15px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
    }
`;

const SponsorCheckerSubText = styled(Typography)`
    && {
        text-align: center;
        font-size: 12px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: white;
    }
`;

const IntroImg = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 500px;
    max-width: 100%;
`;

const InnerImg = styled.img`
    position: absolute;
    max-width: 100%;
    max-height: 100%;
`;

const OuterImg = styled.img`
    position: absolute;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    max-width: 100%;
    max-height: 100%;
    @keyframes floating {
        0% {
            transform: translate(0, 0px);
        }
        50% {
            transform: translate(0, -15px);
        }
        100% {
            transform: translate(0, -0px);
        }
    }
`;
export default function IntroContainer() {
    const theme = useTheme();
    const useMobileDisplay = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <IntroSectionContainer>
            <IntroSection>
                <IntroTextWrapper>
                    <IntroTitle>Opportunity Awaits</IntroTitle>
                    <IntroText>
                        Elevate your job search with Sponsor Checker - free and user-friendly, instantly accessing
                        10,000+ H1B sponsorship and E-Verify data for any company, all while you browse the web.
                    </IntroText>
                    <IntroButtonGroup>
                        <SponsorCheckerBtn
                            href="https://chrome.google.com/webstore/detail/aitou-visa-sponsor-checke/kkdfahnaohddaenhpefgbmkgkpnakamo"
                            variant="contained"
                            target="_blank"
                            color="primary"
                            fullWidth={useMobileDisplay}
                        >
                            <SponsorCheckerText>Get Sponsor Checker</SponsorCheckerText>
                            <SponsorCheckerSubText>It’s free!</SponsorCheckerSubText>
                        </SponsorCheckerBtn>
                        {/*<FindReferralBtn*/}
                        {/*    href="https://docs.google.com/forms/d/e/1FAIpQLSfbDYMv3ojCU7mP5IVQSv6k7_NmehRW3vt2lcsIYHFYSTScyg/viewform"*/}
                        {/*    variant="outlined"*/}
                        {/*    target="_blank"*/}
                        {/*    color="primary"*/}
                        {/*    fullWidth={useMobileDisplay}*/}
                        {/*>*/}
                        {/*    <FindReferralText>Find Referrals (Beta)</FindReferralText>*/}
                        {/*</FindReferralBtn>*/}
                    </IntroButtonGroup>
                </IntroTextWrapper>
                {!useMobileDisplay && (
                    <IntroImg>
                        <InnerImg src="https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/homepage+pic+1.svg"></InnerImg>
                        <OuterImg src="https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/homepage+pic+2.svg"></OuterImg>
                    </IntroImg>
                )}
            </IntroSection>
        </IntroSectionContainer>
    );
}

import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Row, Col, Container, Button } from 'react-bootstrap';

import * as APIs from '../../apis/APIs';
import { Avatar, IconButton } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { ReferralRequestDetailModel } from '../../../interfaces/referral/ReferralRequestDetailModel';
import { ReferrerDetailModel } from '../../../interfaces/referral/ReferrerDetailModel';
import ReferralPostDetail from '../referral-post-detail/ReferralPostDetail';
import RequestDetails from '../referrer-dashboard/RequestDetails';
import { AuthContext } from '../../../app/App';
import { ReferralRequestStatus } from '../../../enums/ReferralRequestStatus';
import './ReferralApplicationDetailPage.scss';
import ReferrerInfo from '../referrer-dashboard/ReferrerInfo';
import { ReferrerInfoDisplayType } from '../referrer-dashboard/ReferrerInfo/ReferrerInfo';
import { ReferralPostDetailModel } from '../../../interfaces/referral/ReferralPostDetailModel';
import { CancelFormButtonGroup } from '../../shared/ApplicationForm';
import { ProfileLeftSplitWrap, ProfileRightSplitWrap } from '../../shared/PanelComponent';

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        marginRight: 84,
        width: 125,
        minWidth: 125,
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '16px',
    },
    tabBtnGroup: {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    rightSplitWrap: {
        backgroundColor: 'white',
        paddingRight: '1.5rem',
        paddingLeft: '1.5rem',
        fontFamily: 'Roboto',
        borderRadius: '10px',
    },
    formContainer: {
        marginTop: 100,
    },
    leftSplitView: {
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
    },
    returnButtonWrap: {
        height: 110,
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        left: 50,
    },
    sectionHeader: {
        fontSize: '16px',
        marginTop: '20px',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginBottom: 20,
    },
    referrerInfo: {
        marginBottom: 10,
    },
    postStatusBlock: {
        display: 'flex',
        flexDirection: 'column',
    },
    postStatus: {
        paddingBottom: 10,
        position: 'relative',
    },
    statusText: {
        marginLeft: 10,
    },
    currentStatus: {
        color: '#1e60b9',
    },
    nonCurrentStatus: {
        color: 'lightgray',
    },
}));

const ReferralDetailPageBackButton = styled(CancelFormButtonGroup)`
    top: 25px;
    left: -80px;
`;

const ReferralDetailLeftSplitWrap = styled(ProfileLeftSplitWrap)`
    background: white;
    padding: 30px 24px;
    height: calc(100vh - 165px);
    border-radius: 10px;
`;

const ReferralDetailRightSplitWrap = styled(ProfileRightSplitWrap)`
    height: calc(100vh - 165px);
    background: white;
    border-radius: 10px;
`;

const ReferralDetailLeftHeader = styled.div`
    margin-bottom: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
`;

interface RefererApplicationDetailPageProps {
    userId: any;
    data: ReferralRequestDetailModel;
    closeHandler: any;
    referralPost: ReferralPostDetailModel | undefined;
}

const RefererApplicationDetailPage = (props: RefererApplicationDetailPageProps) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [referrerDetails, setReferrerDetails] = useState<ReferrerDetailModel | undefined>(null);
    const [reqs, setReqs] = useState([]);
    const history = useHistory();
    const classes = useStyles();
    const [selectedReqId, setSelectedReqId] = useState(null);
    const [selectedReq, setSelectedReq] = useState(null);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(null);

    const changeReqStatus = (req: ReferralRequestDetailModel, newStatus: ReferralRequestStatus) => {
        const newReqs = JSON.parse(JSON.stringify(reqs));
        const tempReq = JSON.parse(JSON.stringify(req));
        const index = reqs.indexOf(req);
        tempReq.status = newStatus;
        if (index != -1) {
            newReqs[index] = tempReq;
        }
        setReqs(newReqs);
        setSelectedReqId(null);
        setSelectedReq(null);

        APIs.ReferralRequestEdit(props.userId, req.id, newStatus);
    };

    const statusTextMapping = {
        submitted: 'Submitted',
        referred: 'Acknowledged by referrer',
        rejected: 'Declined by referrer',
        unfinished: 'Not submitted',
    };
    const [statusTrace, setStatusTrace] = useState<string[]>([statusTextMapping.submitted, statusTextMapping.referred]);
    useEffect(() => {
        // APIs.ReferrerRead(props.userId, props.data.referrer_id);
        if (props.data && props.data.status) {
            let statusTraceList: string[] = [];
            switch (props.data.status) {
                case ReferralRequestStatus.Pending:
                    statusTraceList = [statusTextMapping.submitted];
                    break;
                case ReferralRequestStatus.Referred:
                    statusTraceList = [statusTextMapping.submitted, statusTextMapping.referred];
                    break;
                case ReferralRequestStatus.Rejected:
                    statusTraceList = [statusTextMapping.submitted, statusTextMapping.rejected];
                    break;
                case ReferralRequestStatus.Unfinished:
                    statusTraceList = [statusTextMapping.unfinished];
                    break;
                default:
                    statusTraceList = [];
            }
            setStatusTrace(statusTraceList);
        }
    }, [props.data]);

    const onCurrentTabChange = (_, index) => {
        setCurrentTabIndex(index);
    };

    return (
        <div>
            {!isLoading && (
                <React.Fragment>
                    <ReferralDetailPageBackButton>
                        <div onClick={props.closeHandler}>
                            <img src="https://s3.amazonaws.com/aitou.io.bucket/icons/backIcon.svg" />
                        </div>
                    </ReferralDetailPageBackButton>
                    <Container>
                        <Row>
                            <ReferralDetailLeftSplitWrap>
                                <ReferralDetailLeftHeader>Status</ReferralDetailLeftHeader>
                                <div>
                                    {statusTrace.map((status, idx) => (
                                        <div
                                            key={idx}
                                            className={`${classes.postStatus} ${
                                                statusTrace.length > 1 ? 'post-status-decor' : ''
                                            }`}
                                        >
                                            <span
                                                className={
                                                    idx === statusTrace.length - 1
                                                        ? classes.currentStatus
                                                        : classes.nonCurrentStatus
                                                }
                                            >
                                                &#11044;
                                            </span>
                                            &nbsp;
                                            <span className={classes.statusText}>{status}</span>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <>
                                        <div className={classes.sectionHeader}>Referrer</div>

                                        <ReferrerInfo
                                            userId={props.userId}
                                            referrerId={props.referralPost && props.referralPost.referrer_id}
                                            displayLocation={ReferrerInfoDisplayType.ReferralApplicationStatus}
                                            hideBorder={true}
                                        />
                                    </>
                                </div>
                            </ReferralDetailLeftSplitWrap>
                            <ReferralDetailRightSplitWrap>
                                <div className={classes.rightSplitWrap}>
                                    <AuthContext.Consumer>
                                        {({ userId }) => (
                                            <RequestDetails
                                                curType={null}
                                                selectedReq={props.data}
                                                changeReqStatus={changeReqStatus}
                                                showCompanyHeader
                                                userId={Number(userId)}
                                                isLoading={false}
                                            />
                                        )}
                                    </AuthContext.Consumer>
                                </div>
                            </ReferralDetailRightSplitWrap>
                        </Row>
                    </Container>
                </React.Fragment>
            )}
        </div>
    );
};
export default RefererApplicationDetailPage;

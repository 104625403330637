import './UserAuth.scss';

import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import AuthDialog from './auth-dialog/AuthDialog';
import UserSignUp from './forms/UserSignUp';
import WaitingVerification from './forms/WaitingVerification';
import UserSignIn from './forms/UserSignIn';
import ForgotPassword from './forms/ForgotPassword';
import ResetPassword from './forms/ResetPassword';
import * as AuthHelper from './helpers/AWSHelpers';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UserContext, HandlerContext } from '../../app/App';

import { userGet } from '../apis/APIs';
import { signOut } from './helpers/AWSHelpers';
import { ActionButton } from '../shared/ActionButton';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';

const SignInButtonContainer = styled.div`
    margin-left: 16px;
    .MuiButton-root {
        padding: 8px 16px;
        border: 1px solid #165ebf;
        border-radius: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        text-align: right;
        text-transform: capitalize;

        /* Primary/Primary Blue */

        color: #165ebf;
    }
    @media (max-width: 960px) {
        margin-left: 0;
    }
`;

const NavAvatarContainer = styled.div`
    .MuiAvatar-root {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
    }
    @media (max-width: 960px) {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        color: #6b7280;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const SignInButton = styled(Button)`
    max-height: 40px;
`;

const ProfileButton = styled.button`
    text-transform: none;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
`;

interface UserAuthProps {
    hideProfileAvatar?: boolean;
}

const UserAuth = (props: RouteComponentProps<any> & UserAuthProps) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [referrerId, setReferrerId] = useState(null);
    const [userId, setUserId] = useState('');
    const [signingUp, setSigningUp] = useState(false);
    const [waitingVerification, setWaitingVerification] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [forgotPassword, setForgotPassowrd] = useState(false);
    const [resettingPassword, setResettingPassword] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [signedInUserData, setSignedInUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { authStateUpdateHandler, userContextUpdateHandler } = useContext(HandlerContext);

    const theme = useTheme();
    const useMobileDisplay = useMediaQuery(theme.breakpoints.down('sm'));

    const jumpToJobsPage = () => {
        const location = props.location.pathname;
        if (location !== '/jobs') {
            props.history.push('/jobs');
        }
    };

    const showVerification = (username: string, password: string) => {
        setWaitingVerification(true);
        setSigningIn(false);
        setSigningUp(false);
        setUsername(username);
        setPassword(password);
    };
    const handleConfirmingUser = (response: any, username: string) => {
        setWaitingVerification(false);
        setSigningUp(false);
        setUsername(username);
    };
    const showCreateAccount = () => {
        setSigningIn(false);
        setSigningUp(true);
    };
    const showSignIn = () => {
        setSigningIn(true);
        setSigningUp(false);
        setWaitingVerification(false);
        setForgotPassowrd(false);
        setResettingPassword(false);
    };
    const showForgotPassword = () => {
        setSigningIn(false);
        setSigningUp(false);
        setWaitingVerification(false);
        setForgotPassowrd(true);
        setResettingPassword(false);
    };
    const handleForgotPasswordEmailSubmit = (username: string) => {
        setForgotPassowrd(false);
        setResettingPassword(true);
        setUsername(username);
    };
    const handleResetPassword = (username: string) => {
        setSigningIn(false);
        setSigningUp(false);
        setWaitingVerification(false);
        setForgotPassowrd(false);
        setResettingPassword(false);
        setUsername(username);
    };
    const handleSetUsername = (username: string) => {
        setUsername(username);
    };
    const handleSetUserId = (userId: string) => {
        setUserId(userId);
    };
    const handleSignOutAttempt = () => {
        signOut()
            .then((res) => {
                setSigningIn(false);
                setSignedInUserData(null);
                setUserId(null);
                authStateUpdateHandler(null, null);
                userContextUpdateHandler(null, null, null);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleSignInAttempt = (user: any) => {
        AuthHelper.currentUserInfo()
            .then((data) => {
                setUserId(data && data['attributes'] && data['attributes']['custom:user_id']);
                if (data && data.username) {
                    setIsSignedIn(true);
                    setUsername(data.username);
                    setSignedInUserData(data.attributes);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        setSigningIn(false);
        setIsSignedIn(true);
    };

    const handleCloseWindow = () => {
        setSigningIn(false);
        setSigningUp(false);
        setWaitingVerification(false);
        setForgotPassowrd(false);
        setResettingPassword(false);
    };
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleProfileClick = () => {
        props.history.push(`/users/profile/${signedInUserData['custom:user_id']}`);
    };

    useEffect(() => {
        if (signedInUserData && signedInUserData['custom:user_id']) {
            userGet(signedInUserData['custom:user_id']).then(async (user) => {
                setReferrerId(user['referrer_id']);
                userContextUpdateHandler(user['first_name'], user['last_name'], user['profile_image_filename']);
                authStateUpdateHandler(signedInUserData['custom:user_id'], user['referrer_id']);
            });
        }
    }, [signedInUserData]);

    const useStyles = makeStyles((theme) => ({
        avatar: {
            border: '0.5px solid grey',
            width: '32px',
            height: '32px',
            cursor: 'pointer',
        },
    }));

    const classes = useStyles();

    const NavBarButtonWrapper = (text: string, handler: any, extraClasses = 'sign-in-button') => {
        return (
            <SignInButtonContainer>
                {text !== 'Sign Out' ? (
                    <SignInButton
                        className={'nav-link ' + extraClasses}
                        variant="outlined"
                        color="primary"
                        onClick={handler}
                    >
                        {text}
                    </SignInButton>
                ) : (
                    <React.Fragment>
                        {!props.hideProfileAvatar && (
                            <ProfileButton onClick={useMobileDisplay ? null : handleClick}>
                                <NavAvatarContainer>
                                    <UserContext.Consumer>
                                        {({ profileImageFilename }) => {
                                            if (signedInUserData && profileImageFilename) {
                                                return (
                                                    <>
                                                        <Avatar
                                                            className={classes.avatar}
                                                            src={`https://s3.amazonaws.com/aitou-users-public-attachments/${signedInUserData['custom:user_id']}/${profileImageFilename}`}
                                                        />
                                                        {useMobileDisplay && !!username && username}
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <Avatar
                                                            alt="avatar"
                                                            style={{ color: 'white', backgroundColor: '#165ebf' }}
                                                            className={classes.avatar}
                                                        >
                                                            {username.charAt(0)}
                                                        </Avatar>
                                                        {useMobileDisplay && !!username && username}
                                                    </>
                                                );
                                            }
                                        }}
                                    </UserContext.Consumer>
                                </NavAvatarContainer>
                            </ProfileButton>
                        )}
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {/*<MenuItem onClick={handleProfileClick}>Profile</MenuItem>*/}
                            <MenuItem onClick={handler}>Logout</MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </SignInButtonContainer>
        );
    };

    useEffect(() => {
        let mounted = true;
        AuthHelper.currentUserInfo()
            .then((res) => {
                if (res && res.username && mounted) {
                    setIsSignedIn(true);
                    setUsername(res.username);
                    setSignedInUserData(res.attributes);
                }
            })
            .catch((err) => {
                if (mounted) {
                    console.error(err.message);
                }
            })
            .finally(() => {
                if (mounted) {
                    setIsLoading(false);
                }
            });
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <React.Fragment>
            {isLoading && NavBarButtonWrapper('Loading...', null)}
            {!isLoading && !isSignedIn && (
                <React.Fragment>{NavBarButtonWrapper('Sign In', () => showSignIn())}</React.Fragment>
            )}
            {isSignedIn && NavBarButtonWrapper('Sign Out', () => handleSignOutAttempt())}
            {forgotPassword && (
                <AuthDialog open={forgotPassword} close={() => handleCloseWindow()} page={'signIn'}>
                    <ForgotPassword
                        done={(usrname: string) => handleForgotPasswordEmailSubmit(usrname)}
                        backHandler={() => showSignIn()}
                    />
                </AuthDialog>
            )}
            {resettingPassword && (
                <AuthDialog open={resettingPassword} close={() => handleCloseWindow()} page={'signUp'}>
                    <ResetPassword done={(usrname: string) => handleResetPassword(usrname)} username={username} />
                </AuthDialog>
            )}
            {signingUp && (
                <AuthDialog open={signingUp} close={() => handleCloseWindow()} page={'signUp'}>
                    <UserSignUp
                        done={(r: any, username: string) => handleConfirmingUser(r, username)}
                        setUsernameHandler={(username: string) => handleSetUsername(username)}
                        setUserIdHandler={(userId: string) => handleSetUserId(userId)}
                        confirmHandler={(username: string, password: string) => showVerification(username, password)}
                        returnToSignInHandler={() => showSignIn()}
                        signInHandler={(r: any) => handleSignInAttempt(r)}
                    />
                </AuthDialog>
            )}
            {waitingVerification && (
                <AuthDialog open={waitingVerification} close={() => handleCloseWindow()} page={'verification'}>
                    <WaitingVerification
                        username={username}
                        password={password}
                        setUserIdHandler={(userId: string) => handleSetUserId(userId)}
                        done={(r: any) => handleConfirmingUser(r, username)}
                        signInHandler={(r: any) => handleSignInAttempt(r)}
                    />
                </AuthDialog>
            )}
            {signingIn && (
                <AuthDialog open={signingIn} close={() => handleCloseWindow()} page={'signIn'}>
                    <UserSignIn
                        username={username}
                        done={(r: any) => handleSignInAttempt(r)}
                        confirmHandler={(username: string, password: string) => showVerification(username, password)}
                        forgotPasswordHandler={() => showForgotPassword()}
                        showCreateAccountHandler={() => showCreateAccount()}
                    />
                </AuthDialog>
            )}
        </React.Fragment>
    );
};

export default withRouter(UserAuth);

import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export const Section = styled(Container)`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`;

export const Title = styled.div`
    margin-top: 100px;
    margin-bottom: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 44px;
    /* identical to box height, or 105% */

    display: flex;
    align-items: center;

    /* Primary/Primary Blue */

    color: #165ebf;
`;

export const SubTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    color: #000000;
`;

export const CardGroup = styled(Row)`
    z-index: 10;
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
    align-items: center;
`;

export const ActionButton = styled(Link)`
    text-transform: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    display: flex;
    align-items: center;

    /* Primary/Primary Blue */

    color: #165ebf;
`;

export const ArrowRightAltIconWrapper = styled(ArrowRightAltIcon)`
    margin-right: 8px;
`;

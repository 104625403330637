import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga';

const TextContainer = styled(Container)`
    margin-top: 140px;
    margin-bottom: 142px;
    font-family: 'Roboto';
    color: #122534;
`;

const SectionHeaderWrapper = styled.h5`
    margin-top: 35px;
`;

const IntroTitle = styled.h1`
    font-style: normal;
    font-weight: 700;
`;

const Privacy = () => {
    ReactGA.initialize('UA-180746720-1');
    ReactGA.pageview('/privacy');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TextContainer>
                <IntroTitle>Privacy Policy</IntroTitle>
                <p />
                <SectionHeaderWrapper>Introduction</SectionHeaderWrapper>
                <p>
                    Our company, AiTou, is committed to protecting the privacy of our clients, candidates, and
                    website/extension users. This privacy policy explains how we collect, use, and disclose personal
                    information in the course of providing our recruitment services.
                </p>

                <SectionHeaderWrapper>Information Collection</SectionHeaderWrapper>
                <p>
                    We collect information from clients, candidates, and website/extension users in a variety of ways,
                    including:
                </p>
                <ul>
                    <li>
                        Information provided by clients and candidates when registering for our services or submitting a
                        job or referral application
                    </li>
                    <li>
                        Information collected through the use of our website/extension, including browser type and IP
                        address
                    </li>
                    <li>Information obtained from third-party sources, such as professional networking sites</li>
                </ul>

                <SectionHeaderWrapper>Use of Information</SectionHeaderWrapper>
                <p>We use the information we collect for the following purposes:</p>
                <ul>
                    <li>
                        To provide our recruitment services, including matching candidates with job opportunities and
                        communicating with clients and candidates
                    </li>
                    <li>To improve our website/extension and services</li>
                    <li>To comply with legal and regulatory requirements</li>
                </ul>

                <SectionHeaderWrapper>Disclosure of Information</SectionHeaderWrapper>
                <p>We may disclose personal information to third parties for the following purposes:</p>
                <ul>
                    <li>To clients for the purpose of providing our recruitment services</li>
                    <li>
                        To third-party service providers, such as background check providers and reference checkers, in
                        order to verify information provided by candidates.
                    </li>
                    <li>To comply with legal and regulatory requirements</li>
                </ul>

                <SectionHeaderWrapper>Data Retention</SectionHeaderWrapper>
                <p>
                    We will retain your personal information for as long as it is necessary to fulfill the purposes
                    outlined in this privacy policy, or as required by law.
                </p>

                <SectionHeaderWrapper>Data Security</SectionHeaderWrapper>
                <p>
                    We take appropriate security measures to protect against unauthorized access to or unauthorized
                    alteration, disclosure, or destruction of data. These include internal reviews of our data
                    collection, storage, processing practices, and security measures, as well as physical security
                    measures to guard against unauthorized access to systems where we store personal data.
                </p>

                <SectionHeaderWrapper>Your Rights</SectionHeaderWrapper>
                <p>
                    You have the right to access, correct, and delete the personal information that we have collected
                    about you. You also have the right to object to the processing of your personal information for
                    certain purposes. To exercise these rights, please contact us at developer@aitou.io or WeChat
                    (lower-right corner of the page).
                </p>

                <SectionHeaderWrapper>Changes to this Privacy Policy</SectionHeaderWrapper>
                <p>
                    We may update this privacy policy from time to time to reflect changes in our practices or
                    applicable laws. We will provide notice of any material changes by posting the new policy on our
                    website.
                </p>

                <SectionHeaderWrapper>Cookies</SectionHeaderWrapper>
                <p>
                    Our website may use cookies to improve your experience while using our site. Cookies are small text
                    files that are stored on your device by the website. They are used to remember your preferences and
                    to track your usage of the site. You can choose to disable cookies in your browser settings, but
                    this may affect the functionality of our website.
                </p>

                <SectionHeaderWrapper>GDPR Compliance</SectionHeaderWrapper>
                <p>
                    We take the protection of your personal information seriously and comply with the General Data
                    Protection Regulation (GDPR). We have implemented appropriate technical and organizational measures
                    to ensure that your personal information is processed in accordance with the GDPR.
                </p>

                <SectionHeaderWrapper>Children&apos;s Privacy</SectionHeaderWrapper>
                <p>
                    Our website is not intended for children under the age of 16. We do not knowingly collect personal
                    information from children under the age of 16. If we become aware that we have collected personal
                    information from a child under the age of 16, we will take steps to delete the information as soon
                    as possible.
                </p>

                <SectionHeaderWrapper>Contact Us</SectionHeaderWrapper>
                <p>
                    If you have any questions or concerns about this privacy policy or our information practices, please
                    contact us at developer@aitou.io or WeChat (lower-right corner of the page).
                </p>

                <p>
                    By using our website and services, you consent to the collection, use, and disclosure of your
                    personal information in accordance with this privacy policy. If you do not agree with the terms of
                    this privacy policy, please do not use our website or services.
                </p>
            </TextContainer>
        </>
    );
};

export default Privacy;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga';

const TextContainer = styled(Container)`
    margin-top: 140px;
    margin-bottom: 142px;
    font-family: 'Roboto';
    color: #122534;
`;

const SectionHeaderWrapper = styled.h5`
    margin-top: 35px;
`;

const IntroTitle = styled.h1`
    font-style: normal;
    font-weight: 700;
`;

const CookiesPolicy = () => {
    ReactGA.initialize('UA-180746720-1');
    ReactGA.pageview('/cookies-policy');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TextContainer>
                <IntroTitle>Cookies Policy</IntroTitle>
                <p />
                <SectionHeaderWrapper>Introduction</SectionHeaderWrapper>
                <p>
                    Our company, AiTou, uses cookies on our website to improve your user experience and to better
                    understand how our website is used. This Cookies policy explains what cookies are, how we use them,
                    and how you can manage your cookie preferences.
                </p>

                <SectionHeaderWrapper>What are cookies?</SectionHeaderWrapper>
                <p>
                    Cookies are small text files that are stored on your device by the website you visit. They are used
                    to remember your preferences and to track your usage of the site. Cookies can be either session
                    cookies, which are deleted when you close your browser, or persistent cookies, which remain on your
                    device until they expire or are deleted.
                </p>

                <SectionHeaderWrapper>How we use cookies</SectionHeaderWrapper>
                <p>We use cookies on our website for the following purposes:</p>
                <ul>
                    <li>
                        To remember your preferences, such as your language settings, so that you do not have to
                        re-enter them each time you visit our site
                    </li>
                    <li>
                        To analyze how our website is used, including which pages are most popular and how users
                        navigate through the site
                    </li>
                    <li>To personalize the content and ads that are shown to you on our website</li>
                    <li>To improve the security and functionality of our website</li>
                </ul>

                <SectionHeaderWrapper>Third-Party Cookies</SectionHeaderWrapper>
                <p>
                    We may also use third-party cookies, such as Google Analytics, to analyze how our website is used.
                    These cookies are set by third-party companies and are not under our control. You can learn more
                    about these cookies and how to manage them on the third-party&apos;s website.
                </p>

                <SectionHeaderWrapper>Managing your cookie preferences</SectionHeaderWrapper>
                <p>
                    You can manage your cookie preferences by adjusting the settings in your web browser. Most browsers
                    allow you to block or delete cookies, but this may affect the functionality of our website. You can
                    also use tools like the IAB&apos;s Your Online Choices to manage your preferences for third-party
                    cookies.
                </p>

                <SectionHeaderWrapper>Changes to this Cookies Policy</SectionHeaderWrapper>
                <p>
                    We may update this cookies policy from time to time to reflect changes in our practices or
                    applicable laws. We will provide notice of any material changes by posting the new policy on our
                    website.
                </p>

                <SectionHeaderWrapper>Contact Us</SectionHeaderWrapper>
                <p>
                    If you have any questions or concerns about this cookies policy or our use of cookies, please
                    contact us at developer@aitou.io or WeChat (lower-right corner of the page).
                </p>

                <p>
                    By using our website, you consent to the use of cookies in accordance with this cookies policy. If
                    you do not agree with the terms of this Cookies policy, please do not use our website.
                </p>
            </TextContainer>
        </>
    );
};

export default CookiesPolicy;

import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import { Avatar, CircularProgress } from '@material-ui/core';
import { useGradientAvatarStyles } from '@mui-treasury/styles/avatar/gradient';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReferrerInfoInterface } from './ReferrerInfo.interface';
import * as APIs from '../../../apis/APIs';
import { ReferrerInfoModel } from '../../../../interfaces/referral/ReferrerInfoModel';
import {
    ProfileAvartarImg,
    ProfileAvatar,
    ProfileHeaderContainer,
    ProfileUserInfo,
} from '../../../shared/PanelComponent';

const MISSING_NAME_MSG = 'Mysterious Referrer';
const MISSING_SELF_INTRO_MSG = "This referrer hasn't written a self-introduction.";

const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        marginTop: 100,
    },
    userInfoSection: {
        borderRadius: 10,
        paddingTop: 30,
        paddingBottom: 48,
        backgroundColor: 'white',
        width: '100%',
        marginBottom: 20,
        zIndex: -2,
    },
    placeHolderText: {
        fontWeight: 700,
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: 28,
        lineHeight: '80px',
    },
    avatarWrap: {
        margin: '0px auto auto auto',
    },
    editInfo: {},
    nameWrap: {
        margin: '10px auto auto auto',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '20px',
        fontFamily: 'Roboto',
    },
    companyWrap: {
        margin: '10px auto auto auto',
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '20px',
        fontFamily: 'Roboto',
    },
    emailWrap: {
        margin: '10px auto auto auto',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#858995',
        fontFamily: 'Roboto',
    },
    positionWrap: {
        margin: '10px auto auto auto',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#858995',
        fontFamily: 'Roboto',
    },
    introWrap: {
        margin: '40px 50px 57px 50px',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '24px',
        color: '#122534',
        fontFamily: 'Roboto',
    },
    referrerInfo: {
        marginBottom: 10,
    },
    borderLine: {
        borderRadius: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'lightgray',
    },
    topBottomPadding: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    centerItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
}));

const ReferrerInfoContainer = styled.div`
    border-radius: 10px;
    border: ${(props) => (props.className === 'with-border' ? '1px solid #d2d2d2' : '')};
    padding: ${(props) => (props.className === 'with-border' ? '20px' : '')};
`;

const ReferrerAvatar = styled(Col)`
    margin-right: 5px;
`;

const ReferrerName = styled(Row)`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 4px;
`;

const ReferrerCompanyTitle = styled(Row)`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    color: #000000;
`;

const ReferrerDisc = styled.div`
    margin-top: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    color: #000000;
`;

const ReferrerHeaderContainer = styled(ProfileHeaderContainer)``;

const ReferrerInfoSection = styled(ProfileUserInfo)`
    height: calc(100vh - 120px);
    padding: 50px 30px;
    justify-content: start;
`;

const ReferrerIntro = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #122534;
    margin-top: 40px;
`;

const ReferrerAvatarContainer = styled(ProfileAvatar)``;
export enum ReferrerInfoDisplayType {
    ReferrerTracker,
    ReferralApplicationStatus,
    ReferralPostDetail,
}

export const ReferrerInfo = (props: ReferrerInfoInterface) => {
    const classes = useStyles();
    const styles = useGradientAvatarStyles({
        size: 80,
        gap: 0,
        thickness: 0,
        gapColor: 'black',
        color: '#165EBF',
    });

    const [referrerInfo, setReferrerInfo] = useState<ReferrerInfoModel | undefined>(null);
    const [referrerCompanyDetail, setReferrerCompanyDetail] = useState(null);
    const [avatarInitials, setAvatarInitials] = useState(null);
    const [profileImageFilename, setProfileImageFilename] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        if (props.referrerId) {
            setIsLoading(true);
            APIs.ReferrerRead(props.userId, props.referrerId)
                .then((data: ReferrerInfoModel) => {
                    setReferrerInfo(data);
                    if (data.profile_image_filename) {
                        setProfileImageFilename(data.profile_image_filename);
                    } else {
                        setProfileImageFilename(null);
                    }
                    let initials = '';
                    if (data.first_name) {
                        initials = data.first_name[0].toLocaleUpperCase();
                    }
                    if (data.last_name) {
                        initials += data.last_name[0].toLocaleUpperCase();
                    }
                    setAvatarInitials(initials);
                    if (data.company_id) {
                        setIsLoading(true);
                        APIs.companyGET(data.company_id)
                            .then((companyData) => {
                                setReferrerCompanyDetail(companyData);
                            })
                            .catch((error) => {
                                console.error(`failed to load company info for #${data.company_id}. Message: ${error}`);
                            })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.referrerId]);

    const referrerAvatar = useMemo(() => {
        if (!profileImageFilename) {
            return <div className={classes.placeHolderText}>{avatarInitials ? avatarInitials : '--'}</div>;
        }

        return (
            <Avatar
                src={`https://s3.amazonaws.com/aitou-users-public-attachments/${referrerInfo.user_id}/${profileImageFilename}`}
            />
        );
    }, [profileImageFilename]);

    const referrerTrackerTemplate = () => (
        <ReferrerHeaderContainer>
            {!referrerInfo && <ReferrerInfoSection>Referrer Info Unavailable</ReferrerInfoSection>}
            {referrerInfo && (
                <ReferrerInfoSection>
                    {/* TODO: add edit referrer info function */}
                    {/* <div className={classes.editInfo}>
                        <EditOutlined style={{ color: '#165EBF' }}></EditOutlined>
                    </div> */}
                    <Row style={{ position: 'relative' }}>
                        <div className={classes.avatarWrap}>
                            <div className={styles.root}>{referrerAvatar}</div>
                        </div>
                    </Row>
                    <Row>
                        <div className={classes.nameWrap}>
                            {referrerInfo.first_name && referrerInfo.last_name
                                ? `${referrerInfo.first_name} ${referrerInfo.last_name}`
                                : MISSING_NAME_MSG}
                            {/* //TODO: Edit Referrer profile is not supported yet */}
                            {/* <EditOutlined style={{ color: '#165EBF' }}></EditOutlined> */}
                        </div>
                    </Row>
                    <Row>
                        <div className={classes.companyWrap}>
                            {referrerCompanyDetail && referrerCompanyDetail.title}
                        </div>
                    </Row>
                    {/* TODO: uncomment once we support email and job title for the referrer */}
                    {/* <Row>
                    <div className={classes.emailWrap}>adfdaf@amazon.com</div>
                </Row>
                <Row>
                    <div className={classes.positionWrap}>Front-end Engineer</div>
                </Row> */}
                    <ReferrerIntro>
                        {referrerInfo.description ? referrerInfo.description : MISSING_SELF_INTRO_MSG}
                    </ReferrerIntro>
                </ReferrerInfoSection>
            )}
        </ReferrerHeaderContainer>
    );

    const referralApplicationStatusTemplate = () => (
        <ReferrerInfoContainer className={`${!props.hideBorder ? 'with-border' : ''}`}>
            {!referrerInfo && <div>Referrer Info Unavailable</div>}
            {referrerInfo && (
                <>
                    <Row>
                        <ReferrerAvatar sm={'auto'}>{referrerAvatar}</ReferrerAvatar>
                        <Col>
                            <ReferrerName>
                                {referrerInfo.first_name && referrerInfo.last_name
                                    ? `${referrerInfo.first_name} ${referrerInfo.last_name}`
                                    : MISSING_NAME_MSG}
                            </ReferrerName>
                            <ReferrerCompanyTitle>
                                {referrerCompanyDetail && referrerCompanyDetail.title}
                            </ReferrerCompanyTitle>
                        </Col>
                    </Row>
                    <ReferrerDisc>
                        {referrerInfo.description ? referrerInfo.description : MISSING_SELF_INTRO_MSG}
                    </ReferrerDisc>
                </>
            )}
        </ReferrerInfoContainer>
    );

    const loadingMask = (component: React.ReactElement) => {
        return (
            <>
                {isLoading ? (
                    <div className={classes.centerItem}>
                        <CircularProgress />
                    </div>
                ) : (
                    component
                )}
            </>
        );
    };
    const referralPostDetailTemplate = () => (
        <Container className={classes.topBottomPadding}>
            <>{referralApplicationStatusTemplate()}</>
        </Container>
    );

    switch (props.displayLocation) {
        case ReferrerInfoDisplayType.ReferrerTracker:
            return loadingMask(referrerTrackerTemplate());
        case ReferrerInfoDisplayType.ReferralApplicationStatus:
            return loadingMask(referralApplicationStatusTemplate());
        case ReferrerInfoDisplayType.ReferralPostDetail:
            return loadingMask(referralPostDetailTemplate());
        default:
            return loadingMask(referrerTrackerTemplate());
    }
};

export default ReferrerInfo;

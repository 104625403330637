import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import './fonts/PT-Root-UI_Bold.woff';
import './fonts/PT-Root-UI_Regular.woff';
import 'bootstrap/dist/css/bootstrap.min.css';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import apiConfig from './config/apiConfig';
import s3Config from './config/s3Config';
Amplify.configure(config);
Amplify.configure(apiConfig.API);
Amplify.configure(s3Config);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

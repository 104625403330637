import React, { useState } from 'react';
import * as AuthHelper from '../helpers/AWSHelpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Row from 'react-bootstrap/Row';
import {
    UserAuthForm,
    UserAuthFormControl,
    UserAuthFormControlLabel,
    UserAuthFormSubtitle,
    UserAuthIcon,
    UserAuthInputField,
} from '../../shared/UserAuthForm';

interface ForgotPasswordProps {
    done: any;
    backHandler: any;
}

const useStyles = makeStyles((theme) => ({
    email: {
        marginTop: '20px',
    },
}));

export default function ForgotPassword(props: ForgotPasswordProps) {
    const classes = useStyles();
    // in all cases, username should be user's email
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');

    const handleChangeUsername = (val: any) => setUsername(val.target.value);

    // Trigger an alert on form submission
    const handleSubmit = (event: any) => {
        if (!validateForm()) {
            return;
        }
        event.preventDefault();

        AuthHelper.forgotPassword(username)
            .then((res) => {
                props.done(username);
            })
            .catch((err) => {
                console.error(err);
                setError(err.message);
            });
    };

    const validateForm = () => {
        if (!username) {
            setError('Email, password or confirm password is missing');
        } else {
            return true;
        }
        return false;
    };

    return (
        <UserAuthForm>
            <UserAuthFormControl>
                <UserAuthFormSubtitle>Enter your email to reset the password</UserAuthFormSubtitle>
                <UserAuthInputField
                    autoFocus
                    fullWidth
                    id="email-adrress"
                    type="email"
                    variant="outlined"
                    placeholder="Please enter your email"
                    error={!!error}
                    helperText={error}
                    onChange={handleChangeUsername}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/EmailIcon.svg" />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button fullWidth variant="contained" color="primary" type="submit" onClick={handleSubmit}>
                    Send
                </Button>
                <UserAuthFormControlLabel
                    control={
                        <Button className="no-transform" color="primary" onClick={props.backHandler}>
                            Sign In Now
                        </Button>
                    }
                    label="Remember it?"
                    labelPlacement={'start'}
                />
            </UserAuthFormControl>
        </UserAuthForm>
    );
}

import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from './views/Landing';
import Profile from './views/Profile';
import Nav from './views/nav/Nav';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import About from './views/About';
import SponsorshipHistory from './views/SponsorshipHistory';
import UserAuth from '../components/auth/UserAuth';
import MessageMe from '../components/shared/message-me/MessageMe';

import Footer from './views/footer/Footer';
import AuthContextState from '../interfaces/AuthContextState';
import UserContextState from '../interfaces/UserContextState';
import HandlerContextState from '../interfaces/HandlerContextState';
import Privacy from './views/Privacy';
import UserAgreement from './views/UserAgreement';
import CookiesPolicy from './views/CookiesPolicy';

export const AuthContext = React.createContext<AuthContextState>({
    userId: null,
    isLoggedIn: false,
    referrerId: null,
    isReferrer: false,
});

export const UserContext = React.createContext<UserContextState>({
    userFirstName: null,
    userLastName: null,
    profileImageFilename: null,
    setUserFirstName: null,
    setUserLastName: null,
    setProfileImageFilename: null,
});

export const HandlerContext = React.createContext<HandlerContextState>({
    authStateUpdateHandler: null,
    userContextUpdateHandler: null,
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#165ebf',
            contrastText: '#fff',
            dark: ' #0D499A',
            light: '#5090F0',
        },
        secondary: {
            main: '#D83434',
            dark: '#a73c1e',
            light: 'ffef7e',
            contrastText: '#fff',
        },
        tonalOffset: 0.2,
    },
});

const App = () => {
    const [userId, setUserId] = useState<string | null>(null);
    const [referrId, setReferrId] = useState<string | null>(null);
    const [userFirstName, setUserFirstName] = useState<string | null>(null);
    const [userLastName, setUserLastName] = useState<string | null>(null);
    const [profileImageFilename, setProfileImageFilename] = useState<string | null>(null);

    const authStateUpdateHandler = (userId: string, referrerId: string) => {
        setUserId(userId);
        setReferrId(referrerId);
    };

    const userContextUpdateHandler = (
        userFirstName: string | null,
        userLastName: string | null,
        profileImageFilename: string | null,
    ) => {
        setUserFirstName(userFirstName);
        setUserLastName(userLastName);
        setProfileImageFilename(profileImageFilename);
    };

    const navComponent = <Nav userAuthComponent={<UserAuth />} />;

    return (
        <AuthContext.Provider
            value={
                {
                    userId: userId,
                    isLoggedIn: Boolean(userId),
                    referrerId: referrId,
                    isReferrer: Boolean(referrId),
                } as AuthContextState
            }
        >
            <UserContext.Provider
                value={
                    {
                        userFirstName: userFirstName,
                        userLastName: userLastName,
                        profileImageFilename: profileImageFilename,
                        setUserFirstName: setUserFirstName,
                        setUserLastName: setUserLastName,
                        setProfileImageFilename: setProfileImageFilename,
                    } as UserContextState
                }
            >
                <HandlerContext.Provider
                    value={
                        {
                            authStateUpdateHandler: authStateUpdateHandler,
                            userContextUpdateHandler: userContextUpdateHandler,
                        } as HandlerContextState
                    }
                >
                    <ThemeProvider theme={theme}>
                        <Router>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={() => (
                                        <div id="landing-page">
                                            <Landing navComponent={navComponent} />
                                        </div>
                                    )}
                                />
                                <Route
                                    path="/users/profile/:id"
                                    component={() => (
                                        <div id="profile-page">
                                            {navComponent}
                                            <Profile userId={userId} />
                                        </div>
                                    )}
                                />
                                <Route
                                    path="/about"
                                    component={() => (
                                        <div id="about-page">
                                            {navComponent}
                                            <About />
                                        </div>
                                    )}
                                />
                                <Route
                                    path="/privacy"
                                    component={() => (
                                        <div id="privacy-page">
                                            {navComponent}
                                            <Privacy />
                                        </div>
                                    )}
                                />
                                <Route
                                    path="/user-agreement"
                                    component={() => (
                                        <div id="user-agreement-page">
                                            {navComponent}
                                            <UserAgreement />
                                        </div>
                                    )}
                                />
                                <Route
                                    path="/cookies-policy"
                                    component={() => (
                                        <div id="cookies-policy-page">
                                            {navComponent}
                                            <CookiesPolicy />
                                        </div>
                                    )}
                                />
                                <Route
                                    path="/sponsorship-history/:companyName?"
                                    component={() => (
                                        <div id="sponsorship-history-page">
                                            {navComponent}
                                            <SponsorshipHistory
                                                userAuthComponent={<UserAuth hideProfileAvatar={true} />}
                                            />
                                        </div>
                                    )}
                                />
                            </Switch>
                            <MessageMe />
                            <Footer />
                        </Router>
                    </ThemeProvider>
                </HandlerContext.Provider>
            </UserContext.Provider>
        </AuthContext.Provider>
    );
};

export default App;

import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ProfileTabSection = styled.div`
    .MuiTabs-root {
        min-height: 25px;
    }
    .MuiTab-root {
        font-family: 'Roboto';
        min-height: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: none;
        color: black;
        padding: 0;
        margin-right: 50px;
    }

    .MuiTab-textColorInherit.Mui-selected {
        color: #165ebf;
    }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import * as AuthHelper from '../helpers/AWSHelpers';
import Button from '@material-ui/core/Button';
import GoogleIcon from '@mui/icons-material/Google';
import {
    UserAuthButton,
    UserAuthForm,
    UserAuthFormControl,
    UserAuthFormControlLabel,
    UserAuthIcon,
    UserAuthInputField,
    UserAuthInputFieldFirst,
    UserAuthLinkButton,
} from '../../shared/UserAuthForm';
import { InputAdornment } from '@material-ui/core';
import { ActionButton } from '../../shared/ActionButton';

interface UserSignInProps {
    done: any;
    confirmHandler: any;
    username: string;
    forgotPasswordHandler: any;
    showCreateAccountHandler: any;
}

const UserAuthDivider = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default function UserSignIn(props: UserSignInProps) {
    // in all cases, username should be user's email
    const [username, setUsername] = useState(props.username);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleChangeUsername = (val: any) => {
        setUsername(val.target.value);
        setError('');
    };
    const handleChangePassword = (val: any) => {
        setPassword(val.target.value);
        setError('');
    };
    const handleSubmit = (event: any) => {
        if (!validateForm()) {
            return;
        }
        setIsLoading(true);
        AuthHelper.signIn(username, password)
            .then((user) => {
                props.done(user);
            })
            .catch((error) => {
                if (error.code === 'UserNotConfirmedException') {
                    props.confirmHandler(username, password);
                } else {
                    setError(error.message);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSocialSignIn = (event: any) => {
        AuthHelper.signInWithGoogle();
        //TODO: Connect with app state
    };

    const validateForm = () => {
        if (!username || !password) {
            setError('Username or password is empty');
        } else if (password && password.length < 8) {
            setError('Minimum password length is 8');
        } else {
            return true;
        }
        return false;
    };

    return (
        <UserAuthForm>
            <UserAuthFormControl>
                <UserAuthInputFieldFirst
                    autoFocus
                    required
                    fullWidth
                    id="email-adrress"
                    placeholder="Please enter your email"
                    type="email"
                    variant="outlined"
                    onChange={handleChangeUsername}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/EmailIcon.svg" />
                            </InputAdornment>
                        ),
                    }}
                />
                <UserAuthInputField
                    required
                    fullWidth
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Please enter your password"
                    error={!!error}
                    helperText={error}
                    variant="outlined"
                    onChange={handleChangePassword}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserAuthIcon src="https://s3.amazonaws.com/aitou.io.bucket/icons/passwordIcon.svg" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {showPassword ? (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/hidepasswordicon.svg"
                                        onClick={handleClickShowPassword}
                                    />
                                ) : (
                                    <UserAuthIcon
                                        src="https://s3.amazonaws.com/aitou.io.bucket/icons/showpasswordicon.svg"
                                        onClick={handleClickShowPassword}
                                    />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <UserAuthLinkButton disabled={isLoading} onClick={props.forgotPasswordHandler}>
                    {/* <LockIcon className="mr-1" fontSize="small" />  */}
                    Forgot your password?
                </UserAuthLinkButton>
                <UserAuthButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    Sign In
                </UserAuthButton>
                {/* <UserAuthDivider>
                    <img src="https://s3.amazonaws.com/aitou.io.bucket/icons/divider.svg" />
                </UserAuthDivider>
                <Button variant="outlined" onClick={handleSocialSignIn} startIcon={<GoogleIcon />}>
                    Continue with Google
                </Button> */}
                <UserAuthFormControlLabel
                    control={
                        <Button color="primary" disabled={isLoading} onClick={props.showCreateAccountHandler}>
                            Sign Up Now
                        </Button>
                    }
                    label="Don't have an account?"
                    labelPlacement={'start'}
                />
            </UserAuthFormControl>
        </UserAuthForm>
    );
}

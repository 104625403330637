const apiConfig = {
    API: {
        endpoints: [
            {
                name: 'api',
                endpoint: process.env.REACT_APP_API_GATEWAY_STAGE,
                region: 'us-east-1',
                service: 'lambda',
            },
        ],
    },
};

export default apiConfig;

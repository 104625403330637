import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga';

const TextContainer = styled(Container)`
    margin-top: 140px;
    margin-bottom: 142px;
    font-family: 'Roboto';
    color: #122534;
`;

const SectionHeaderWrapper = styled.h5`
    margin-top: 35px;
`;

const IntroTitle = styled.h1`
    font-style: normal;
    font-weight: 700;
`;

const UserAgreement = () => {
    ReactGA.initialize('UA-180746720-1');
    ReactGA.pageview('/user-agreement');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TextContainer>
                <IntroTitle>User Agreement</IntroTitle>
                <p />
                <SectionHeaderWrapper>1. Introduction</SectionHeaderWrapper>
                <p>
                    Welcome to AiTou! We&apos;re happy to have you here and look forward to helping you find job
                    opportunities, post or apply for referrals, and check company information.
                </p>
                <p>
                    This User Agreement (&quot;Agreement&quot;) is a contract between you and AiTou and applies to your
                    use of AiTou&apos;s services. By accessing or using our services, you confirm that you can form
                    legally binding contracts and that you agree to comply with this Agreement.
                </p>

                <SectionHeaderWrapper>2. Your AiTou Account</SectionHeaderWrapper>
                <p>
                    You&apos;ll need an AiTou account to use some of our services. Keep your account information secure
                    and notify us immediately of any unauthorized use of your account. You&apos;re responsible for all
                    activities that occur under your account, whether you know about them or not.
                </p>

                <SectionHeaderWrapper>3. Your Use of Our Services</SectionHeaderWrapper>
                <p>
                    You agree to use our services only for lawful purposes. You&apos;re not allowed to misuse our
                    services, interfere with their normal operation, or attempt to access them using a method other than
                    through the interface and instructions we provide.
                </p>

                <SectionHeaderWrapper>4. Content</SectionHeaderWrapper>
                <p>
                    AiTou allows you to post content, including job referrals, job applications, and company
                    information. Anything you post remains your responsibility. You agree not to post content that is
                    illegal, offensive, or otherwise harmful to others. We reserve the right to remove any content we
                    deem inappropriate.
                </p>

                <SectionHeaderWrapper>5. Privacy</SectionHeaderWrapper>
                <p>
                    Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use,
                    and share information about you.
                </p>

                <SectionHeaderWrapper>6. Disputes</SectionHeaderWrapper>
                <p>
                    Any disputes arising out of this Agreement shall be governed by the laws of New York. By using our
                    services, you agree to resolve any disputes through court in the jurisdiction of New York.
                </p>

                <SectionHeaderWrapper>7. Changes to This Agreement</SectionHeaderWrapper>
                <p>
                    We may update this Agreement from time to time. If we make significant changes, we will provide you
                    with the appropriate notice.
                </p>

                <SectionHeaderWrapper>8. Contact Information</SectionHeaderWrapper>
                <p>
                    If you have any questions about this Agreement, please contact us at developer@aitou.io or WeChat
                    (lower-right corner of the page).
                </p>

                <p>By using AiTou, you agree to abide by this Agreement. Thank you for joining us!</p>
            </TextContainer>
        </>
    );
};

export default UserAgreement;

import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const IconTextWrapper = styled.div`
    color: #fff;
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
    margin-left: 8px;
`;

const CopyrightTextWrapper = styled.div`
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const FooterContainerWrapper = styled.footer`
    background: #1e60b9;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const UpperContainer = styled(Container)`
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 960px) {
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-top: 28px;
        margin-bottom: 48px;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Links = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 2;
    gap: 24px;
    flex-wrap: wrap;

    @media (max-width: 960px) {
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    }
`;

const StyledLink = styled(Link)`
    color: #fff;
    &:hover {
        color: gray;
    }
`;

const LeftLogoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 1;
`;

const RightLogoContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    flex: 1;
`;

export default function Footer() {
    const aitouLogo = (
        <img src="https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/aitou_logo.svg" alt="aitou_logo" />
    );

    const linkedInLogo = (
        <svg
            height="30"
            viewBox="0 0 72 72"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer' }}
        >
            <g fill="none" fillRule="evenodd">
                <path
                    d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z"
                    fill="#fff"
                />
                <path
                    d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z"
                    fill="#1e60b9"
                />
            </g>
        </svg>
    );

    return (
        <FooterContainerWrapper>
            <UpperContainer>
                {/* TODO: replace this placeholder with the actual logo */}
                <LeftLogoContainer>
                    {aitouLogo}
                    <IconTextWrapper>Aitou</IconTextWrapper>
                </LeftLogoContainer>
                <Links>
                    <StyledLink to="/about">About</StyledLink>
                    <StyledLink to="/privacy">Privacy Policy</StyledLink>
                    <StyledLink to="/cookies-policy">Cookies Policy</StyledLink>
                    <StyledLink to="/user-agreement">User Agreement</StyledLink>
                </Links>
                <RightLogoContainer>
                    <a href="https://www.linkedin.com/company/aitou-technology" target="_blank" rel="noreferrer">
                        {linkedInLogo}
                    </a>
                </RightLogoContainer>
            </UpperContainer>
            <FooterContainer>
                <CopyrightTextWrapper>Copyright &copy; AiTou 2023</CopyrightTextWrapper>
            </FooterContainer>
        </FooterContainerWrapper>
    );
}

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { Section } from '../../components/landing/sections/StyledSectionComponents';
import * as AuthHelper from '../../components/auth/helpers/AWSHelpers';
import { useHistory, useLocation } from 'react-router-dom';

const SponsorshipSectionContainer = styled(Container)`
    min-height: calc(100vh - 200px);
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: center;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

const UnavailableImgWrapper = styled.div`
    max-width: 100%;
`;

const UnavailableImg = styled.img`
    max-width: 100%;
`;

const SponsorshipTitle = styled.div`
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    /* Primary/Primary Blue */

    color: #165ebf;

    @media (max-width: 960px) {
        font-size: 20px;
    }
`;

const SponsorshipText = styled.div`
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    width: 100%;
    color: #858995;
    margin-bottom: 24px;
`;

const SponsorshipSection = styled(Section)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0;
`;

interface SponsorshipHistoryProps {
    userAuthComponent: any;
}
const SponsorshipHistory = (props: SponsorshipHistoryProps) => {
    ReactGA.initialize('UA-180746720-1');
    ReactGA.pageview('/sponsorship-history');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // TODO: will use the companyName to get the corresponding sponsor data
    const company_name = queryParams.get('companyName');
    const history = useHistory();

    useEffect(() => {
        let mounted = true;
        AuthHelper.currentUserInfo()
            .then((res) => {
                if (res && res.username && mounted) {
                    history.push('/');
                }
            })
            .catch((err) => {
                if (mounted) {
                    console.error(err.message);
                }
            });
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <SponsorshipSectionContainer>
            <SponsorshipSection>
                <UnavailableImgWrapper>
                    <UnavailableImg src="https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/working_on_page.svg"></UnavailableImg>
                </UnavailableImgWrapper>
                <SponsorshipTitle>We are currently working on this page.</SponsorshipTitle>
                <SponsorshipText>Sign up now to stay informed about our upcoming feature, coming soon!</SponsorshipText>
                {props.userAuthComponent}
            </SponsorshipSection>
        </SponsorshipSectionContainer>
    );
};

export default SponsorshipHistory;

import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Section, Title } from './StyledSectionComponents';
import { Tabs, Tab, useTheme, useMediaQuery, makeStyles, Grow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const SponsorCheckerSectionContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 80px;
    background: #fbfbfb;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

const SponsorCheckerSection = styled(Section)``;

const SponsorCheckerWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 80px;
    @media (max-width: 960px) {
        gap: 48px;
    }
`;

const SponsorCheckerTitle = styled(Title)`
    font-size: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 48px;
    margin-top: 0;
    @media (max-width: 960px) {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 24px;
    }
`;

const ImgWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TabWrapper = styled.div`
    flex: 1;
`;

const SponsorCheckerTab = withStyles((theme) => ({
    root: {
        color: '#858995',
        borderLeft: '4px solid #D9D9D9',
        textTransform: 'none',
        fontSize: 16,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        padding: 16,
        maxWidth: '100%',
    },
    selected: {
        color: '#165EBF',
    },
    wrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        textAlign: 'left',
    },
    '@media (max-width: 960px)': {
        root: {
            fontWeight: 400,
        },
    },
}))(Tab);

const SponsorCheckerTabs = withStyles((theme) => ({
    indicator: {
        left: 0,
        width: 4,
        backgroundColor: '#165EBF',
    },
}))(Tabs);

const SponsorCheckerImg = styled.img`
    width: 510px;
    height: 330px;
    @media (max-width: 960px) {
        max-width: 100%;
        height: auto;
    }
`;

// Define the image URLs
const imageUrls = [
    'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/sponsor_checker_step1.png',
    'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/sponsor_checker_step2.png',
    'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/sponsor_checker_step3.png',
];

export default function SponsorCheckerContainer() {
    const theme = useTheme();
    const useMobileDisplay = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState('0');

    useEffect(() => {
        const interval = setInterval(() => {
            let index = parseInt(value) + 1;
            if (index >= imageUrls.length) {
                index = 0;
            }
            setValue(index + '');
        }, 8000);
        return () => clearInterval(interval);
    }, [value]);

    const handleChange = (event, value) => {
        setValue(value);
    };

    return (
        <SponsorCheckerSectionContainer fluid>
            <SponsorCheckerSection>
                <SponsorCheckerWrapper>
                    <TabWrapper>
                        <SponsorCheckerTitle>
                            {useMobileDisplay
                                ? 'Works easily while you are applying for jobs on LinkedIn'
                                : 'How Sponsor Checker Works'}
                        </SponsorCheckerTitle>
                        <SponsorCheckerTabs
                            orientation="vertical"
                            onChange={handleChange}
                            value={value}
                            indicatorColor={'primary'}
                        >
                            <SponsorCheckerTab disableRipple value="0" label="Step 1: Add to Chrome in Seconds" />
                            <SponsorCheckerTab
                                disableRipple
                                value="1"
                                label={
                                    useMobileDisplay
                                        ? 'Step 2: Apply on LinkedIn Like Normal'
                                        : 'Step 2: Enable Sponsor Checker on LinkedIn'
                                }
                            />
                            <SponsorCheckerTab disableRipple value="2" label="Step 3: We’ll Do the Work" />
                        </SponsorCheckerTabs>
                    </TabWrapper>
                    <ImgWrapper>
                        <Grow in={value === '0'} timeout={500}>
                            <SponsorCheckerImg
                                src={imageUrls[0]}
                                alt="Step 1"
                                style={{ display: value === '0' ? 'block' : 'none' }}
                            />
                        </Grow>
                        <Grow in={value === '1'} timeout={500}>
                            <SponsorCheckerImg
                                src={imageUrls[1]}
                                alt="Step 2"
                                style={{ display: value === '1' ? 'block' : 'none' }}
                            />
                        </Grow>
                        <Grow in={value === '2'} timeout={500}>
                            <SponsorCheckerImg
                                src={imageUrls[2]}
                                alt="Step 3"
                                style={{ display: value === '2' ? 'block' : 'none' }}
                            />
                        </Grow>
                    </ImgWrapper>
                </SponsorCheckerWrapper>
            </SponsorCheckerSection>
        </SponsorCheckerSectionContainer>
    );
}

import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as AuthHelper from '../../components/auth/helpers/AWSHelpers';
import Company from '../../interfaces/Company';
import Job from '../../interfaces/Job';
import * as APIs from '../../components/apis/APIs';
import { ProfileHeader } from '../../components/profile/profile-header/ProfileHeader';
import { toKababCaseWithPlusSign } from '../../Constants';
import { ReferralTabContent } from '../../components/profile/referral-tab-content/ReferralTabContent';
import { UserContext } from '../App';
import { LoadingSpinner } from '../../components/shared/Loading';
import { ProfileTabSection } from '../../components/shared/TabComponent';
import {
    ProfileContainer,
    ProfileLeftSplitWrap,
    ProfileRightSplitWrap,
    ProfileRow,
    ProfileSection,
} from '../../components/shared/PanelComponent';
const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        marginRight: 84,
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '16px',
        textAlign: 'center',
    },
    tabLong: {
        marginRight: 100,
        width: 160,
        minWidth: 160,
        textTransform: 'none',
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '16px',
    },
    tabReferral: {
        marginRight: 84,
        textTransform: 'none',
        color: '#165EBF',
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '16px',
    },
    badge: {
        transform: 'translate(120%, 22.5%)',
    },
    tabWrapper: {
        alignItems: 'flex-center',
    },
    tabWrapperNoBadge: {
        alignItems: 'center',
    },
}));

interface ProfileProps {
    userId: any;
}

interface UserProfileRouteParams {
    id: string;
}

const Profile = (props: ProfileProps) => {
    const { id } = useParams<UserProfileRouteParams>();
    const [userCustomId, setUserCustomId] = useState(null);
    const [userEmail, setUserEmail] = useState('');
    const [locations, setLocations] = useState([]);
    const [userPreferredRoles, setUserPreferredRoles] = useState([]);
    const [isShowApplicationPage, setIsShowApplicationPage] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [followedCompanies, setFollowedCompanies] = useState<Company[] | undefined>([]);
    const [savedJobs, setSavedJobs] = useState<Job[] | undefined>([]);
    const [resumeFileName, setResumeFileName] = useState<string>('');
    const [resumeId, setResumeId] = useState<number>(null);
    const { setUserFirstName, setUserLastName, setProfileImageFilename } = useContext(UserContext);
    const history = useHistory();
    const classes = useStyles();

    const onCurrentTabChange = (_, index) => {
        setCurrentTabIndex(index);
    };

    const getSavedJobs = async (savedJobsIds) => {
        setIsLoading(true);
        await APIs.jobsGetByIds(savedJobsIds)
            .then(async (response) => {
                setSavedJobs(response['results']);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getFollowingCompanies = async (userId) => {
        setIsLoading(true);
        await APIs.followCompanyGet(userId)
            .then(async (res: any[]) => {
                if (res && res.length > 0) {
                    const companyIdList = res.map((item) => item['company_id']);
                    await APIs.companyGetByIds(companyIdList).then((companies: Company[]) => {
                        setFollowedCompanies(companies);
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getUserDetails = async (userId) => {
        setIsLoading(true);
        await APIs.userGet(userId)
            .then(async (user) => {
                setUserFirstName(user['first_name']);
                setUserLastName(user['last_name']);
                user['preferred_roles'] && setUserPreferredRoles(user['preferred_roles'].split(';'));
                user['locations'] && setLocations(user['locations'].split(';'));
                setProfileImageFilename(user['profile_image_filename']);
                setResumeFileName(user['resume_filename']);
                setResumeId(user['resume_id']);
                if (user && user['saved_jobs'] && user['saved_jobs'].length > 0) {
                    const savedJobsIds = user['saved_jobs'].map((item) => item['job_id']);
                    await getSavedJobs(savedJobsIds);
                }
            })
            .catch((err) => {
                console.log('Some error has occured when trying to fetch user information.');
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        let isCanceled = false;
        AuthHelper.currentUserInfo()
            .then(async (response) => {
                if (response && !isCanceled && response.attributes) {
                    setUserCustomId(response.attributes['custom:user_id']);
                    setUserEmail(response.attributes['email']);
                    await getUserDetails(response.attributes['custom:user_id']);
                    await getFollowingCompanies(response.attributes['custom:user_id']);
                }
            })
            .finally(() => {
                if (!isCanceled) {
                    setIsLoading(false);
                }
            });
        return () => {
            isCanceled = true;
        };
    }, []);

    useEffect(() => {
        if (!isLoading && userCustomId !== id) {
            history.push('/');
        }
    }, [userCustomId, isLoading]);

    const unfollowHandler = (companyId) => {
        APIs.followCompanyDelete(companyId, props.userId)
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(true);
                setFollowedCompanies(followedCompanies.filter((company) => company.id != companyId));
                setIsLoading(false);
            });
    };

    const followHandler = (companyId) => {
        APIs.followCompanyPost(companyId, props.userId).catch((err) => {
            console.error(err);
        });
    };

    const updateProfile = (props) => {
        setUserFirstName(props.firstName);
        setUserLastName(props.lastName);
        if (props.preferredRoleChips) {
            setUserPreferredRoles(props.preferredRoleChips.map((role) => toKababCaseWithPlusSign(role)));
        }

        if (props.location) {
            setLocations([toKababCaseWithPlusSign(props.location)]);
        }

        if (props.profileImageFilename) {
            setProfileImageFilename(props.profileImageFilename);
        }
    };

    return (
        <ProfileSection fluid>
            {isLoading && (
                <LoadingSpinner>
                    <CircularProgress />
                </LoadingSpinner>
            )}
            {!isLoading && (
                <ProfileContainer>
                    <ProfileRow>
                        <ProfileLeftSplitWrap>
                            {!isShowApplicationPage && (
                                <UserContext.Consumer>
                                    {({ userFirstName, userLastName, profileImageFilename }) => (
                                        <ProfileHeader
                                            userId={userCustomId}
                                            userEmail={userEmail}
                                            userFirstName={userFirstName}
                                            userLastName={userLastName}
                                            profileImageFilename={profileImageFilename}
                                            userCustomId={userCustomId}
                                            resumeFileName={resumeFileName}
                                            resumeId={resumeId}
                                            updateProfileHandler={(props) => updateProfile(props)}
                                        />
                                    )}
                                </UserContext.Consumer>
                            )}
                        </ProfileLeftSplitWrap>
                        <ProfileRightSplitWrap md={isShowApplicationPage ? '12' : ''}>
                            {!isShowApplicationPage && (
                                <ProfileTabSection>
                                    <Tabs
                                        indicatorColor="primary"
                                        textColor="inherit"
                                        value={currentTabIndex}
                                        onChange={onCurrentTabChange}
                                        aria-label="profile tabs"
                                    >
                                        <Tab label="Referral" />
                                    </Tabs>
                                </ProfileTabSection>
                            )}
                            {currentTabIndex === 0 && (
                                <ReferralTabContent
                                    isShowApplicationPage={isShowApplicationPage}
                                    setIsShowApplicationPage={setIsShowApplicationPage}
                                    userId={props.userId}
                                ></ReferralTabContent>
                            )}
                        </ProfileRightSplitWrap>
                    </ProfileRow>
                </ProfileContainer>
            )}
        </ProfileSection>
    );
};
export default Profile;

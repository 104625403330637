import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Section, Title } from './StyledSectionComponents';

const SponsorDataSectionContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 80px;
    @media (max-width: 960px) {
        padding-top: 48px;
        padding-bottom: 0;
    }
`;

const SponsorDataSection = styled(Section)``;

const SponsorDataTitle = styled(Title)`
    color: #165ebf;
    text-align: center;
    font-size: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 24px;
    @media (max-width: 960px) {
        font-size: 24px;
        font-weight: 600;
    }
`;

const SponsorDataText = styled.div`
    color: #858995;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const CompanyIconsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 48px;
    @media (max-width: 960px) {
        display: initial;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
`;

const CompanyImg = styled.img`
    max-width: 100%;
    height: auto;
`;

const ImgWrapper = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;
    @media (max-width: 960px) {
        width: 50%;
        margin-bottom: 48px;
        justify-content: flex-start;
        padding-left: 10px;
    }
`;

// Define the image URLs
const companies = [
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/amazon.svg', name: 'amazon' },
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/atlassian.svg', name: 'atlassian' },
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/airbnb.svg', name: 'airbnb' },
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/asana.svg', name: 'asana' },
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/dropbox.svg', name: 'dropbox' },
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/microsoft.svg', name: 'microsoft' },
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/spotify.svg', name: 'spotify' },
    { url: 'https://s3.amazonaws.com/aitou.io.bucket/rebranding+2022/paypal.svg', name: 'paypal' },
];

const CompanyIconsRow = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
`;

export default function SponsorDataContainer() {
    return (
        <SponsorDataSectionContainer fluid>
            <SponsorDataSection>
                <TextWrapper>
                    <SponsorDataTitle>10,000+ H1B Sponsorship and E-Verify Data</SponsorDataTitle>
                    <SponsorDataText>
                        Elevate your job search with AiTou Sponsor Checker - our extensive database of 10,000+ H1B
                        sponsorship and E-Verify data covers big and small companies alike, providing you with the
                        necessary insights to find your dream job in the tech industry.
                    </SponsorDataText>
                </TextWrapper>
                <CompanyIconsWrapper>
                    <CompanyIconsRow>
                        {companies.slice(0, 4).map((company) => (
                            <ImgWrapper key={company.name}>
                                <CompanyImg src={company.url} alt={company.name} />
                            </ImgWrapper>
                        ))}
                    </CompanyIconsRow>
                    <CompanyIconsRow>
                        {companies.slice(4, 8).map((company) => (
                            <ImgWrapper key={company.name}>
                                <CompanyImg src={company.url} alt={company.name} />
                            </ImgWrapper>
                        ))}
                    </CompanyIconsRow>
                </CompanyIconsWrapper>
            </SponsorDataSection>
        </SponsorDataSectionContainer>
    );
}
